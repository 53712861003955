// Global variables
$school-color-teal: paper-color($color-linced-teal, '100');
$school-color-purple: paper-color($color-linced-purple, '300');
$school-color-red: paper-color($color-linced-red, '300');
$school-color-light-green: paper-color($color-linced-light-green, '500');
$school-color-light-blue: paper-color($color-linced-light-blue, '400');
$school-color-orange: paper-color($color-linced-orange, '300');
$school-color-pink: paper-color($color-linced-pink, '400');
$school-color-grey-blue: #5e94c0;
$school-color-dark-green: paper-color($color-linced-dark-green, '400');
$school-color-grey: paper-color($color-grey, '400');

// Parent-app-home-school component's styles that are shared
// between the teachers' and parents' apps

.home-page {
  md-card {
    // Make the card underneath match the school header
    border-radius: 16px;
  }
  .paper-divider {
    // Divider between the menu sections
    margin-top: 20px;
    margin-bottom: 20px;
  }
  &__header {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    // School card headers background colors
    &.teal {
      background-color: $school-color-teal;
    }
    &.purple {
      background-color: $school-color-purple;
    }
    &.red {
      background-color: $school-color-red;
    }
    &.light-green {
      background-color: $school-color-light-green;
    }
    &.light-blue {
      background-color: $school-color-light-blue;
    }
    &.orange {
      background-color: $school-color-orange;
    }
    &.pink {
      background-color: $school-color-pink;
    }
    &.grey-blue {
      background-color: $school-color-grey-blue;
    }
    &.dark-green {
      background-color: $school-color-dark-green;
    }
    &.grey {
      background-color: $school-color-grey;
    }

    &__school-name {
      color: paper-color($color-linced-blue);
      // Tighten school name bottom margins.
      margin: 0;
    }

    &__logo {
      min-width: 120px;
      min-height: 120px;
      max-width: 33%;
      // If the logo is not taking up all of the space,
      // Align it to the top right of the designated space
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        object-fit: contain;
        object-position: right top;
        padding-bottom: 10px;
      }
    }
  }

  &__card-container {
    // Round up the top corners, bring up the container to overlap
    // with the header to see the rounded corners
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 14px !important;
    position: relative;
    top: -10px;
    background-color: white;
  }

  &__menu-section {
    margin-top: 20px;
    // Automatically add new grid columns as the viewport grows
    // to the max of the card size
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-row-gap: 20px;
    justify-items: center;
  }

  &__learners {
    background-color: paper-color($color-grey, '100');
    border-radius: 6px;
    margin-top: 10px;
    padding-top: 10px;
    // Automatically add new grid columns as the viewport grows
    // to the max of the card size
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    justify-items: center;
  }

  // Learner links
  &__learner {
    grid-column: span all;
    line-height: normal;

    // Kids' profile pics
    img {
      object-fit: cover;
      border-radius: 50%;
      width: 80px;
      height: 80px;
      border: paper-color($color-linced-blue) 2px solid;
    }
    p,
    a {
      color: paper-color($color-linced-blue);
      font-weight: 700;
    }
  }

  // Other links on the homepage menu
  &__link {
    line-height: normal;
    width: 79px;

    p,
    a {
      color: var(--foreground);
      // Decrease the font size for the home page's links
      font-size: 0.875rem; // 14px for the base size of 16px
    }

    &__icon {
      &--small {
        height: 62px;
        width: 62px;
        background-color: paper-color($color-grey, '100');
        border-radius: 999px;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          font-size: 32px;
          color: paper-color($color-linced-blue);
        }
        // Custom icons and uploaded images for icons
        img {
          height: 28px;
          width: auto;
          margin: 0 !important;
          object-fit: cover;
        }
      }
    }
  }

  &__link:hover {
    filter: brightness(0.9);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }
}
