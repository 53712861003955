:root {
  --vh-offset: 0px;
}
@import 'color-palette';
@import 'hero-palette';
$primary: 'linced-blue';
$icon-base-color: rgba(0, 0, 0, 0.54);
$warn: 'red';
$success: 'green';

// Import addon styles
@import 'ember-paper';
@import 'paper-data-table';
@import 'ember-paper-stepper';

// Set custom colours (now that we have ember-paper for the `paper-color()` function)
$navbar-main-bg: #5e87b0;
$navbar-filter-bg: #aeaeae;
$navbar-filter-hover: desaturate($navbar-main-bg, 30%);
$top-filter-bg: #eee;
$profile-banner-bg: 'grey';

// Import overrides for addon styles
@import 'linced-common-overrides';
@import 'overrides.scss';

// Import LINC-ED layout and generic UI components
@import 'linced-common';
@import 'layout.scss';
@import 'linced-common-components';
@import 'linced-common-ui-components';
@import 'ui-components.scss';
@import 'paper-badge';

// Import LINC-ED component- & route-specific styles
@import 'assessments';
@import 'goals.scss';
@import 'milestones.scss';
@import 'linced-posts';
@import 'linced-enrolment-form';