// ember-drag-sort - don't allow horizontal scrolling
.dragSortList {
  overflow-x: hidden;
  overflow-y: auto;
}

// Compact 2-line paper-list items
md-list-item.md-2-line.compact-2-line,
md-list-item.md-2-line.compact-2-line > .md-no-style {
  min-height: 64px;
  padding: 0 0 0 8px;

  .md-list-primary.icon-column {
    margin-top: 13px;
    margin-right: 8px;
  }

  // Allow h3 content to wrap onto multiple lines.
  // This prevents long titles from breaking layout on small screens.
  .md-list-item-text h3,
  .md-no-style .md-list-item-text h3 {
    white-space: normal;
  }
}
md-list-item.compact-2-line > md-icon:first-child:not(.md-avatar-icon),
md-list-item.compact-2-line
  .md-list-item-inner
  > md-icon:first-child:not(.md-avatar-icon) {
  margin-right: 16px;
}
md-list-item.md-2-line.compact-2-line::before,
md-list-item.md-2-line.compact-2-line > .md-no-style::before {
  min-height: 64px;
}

md-list-item .icons {
  padding: 0 4px;
  line-height: 18px;

  md-icon {
    margin: 0 2px;
  }
}

// Allow 2-line autocomplete items (including in Chips)
.autocomplete-2-line > small {
  display: none;
}
.md-autocomplete-suggestions li .autocomplete-2-line {
  line-height: normal;
  height: 48px;

  > small {
    display: initial;
  }
}

// Allow step labels to wrap in paper-stepper
.md-stepper-indicator .md-stepper-title {
  white-space: normal;
}

// Show "email address" (grouping name) in group selector for global notices.
.md-contact-suggestion {
  position: relative;

  span.md-contact-email {
    position: absolute;
    top: 16px;
    left: 52px;
  }
}
