.paper-badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  background-color: #d51f27;
  color: #fff;
  margin-left: 8px;

  &--pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    padding-top: 0.5em;
    border-radius: 10rem;
  }

  &--info {
    background-color: paper-color($primary);
  }
}
