#fab-destination {
  position: fixed;
  bottom: 0;
  right: 0;
}

// Make all toolbars the brand yellow color
#main md-toolbar, .site-nav-container md-toolbar, #paper-wormhole md-toolbar {
  background-color: paper-color($color-linced-orange-yellow, '900');
}

md-toolbar {
  &.md-default-theme.sidenav-header {
    md-icon {
      color: #000;
    }
  }
  .md-button {
    min-width: 0;
  }

  .toolbar-logo {
    margin-right: 8px;
  }

  button.active {
    background-color: $navbar-filter-hover;
  }

  &.md-default-theme.filter-bar {
    padding: 0 5px;
    display: inline-block;
    width: auto;
    min-height: 32px;
    margin: 8px;
    background-color: $navbar-filter-bg;

    button.md-button {
      min-width: 20px;
      margin: 2px 0;
      padding: 0 8px;
      border-radius: 0;
      background-color: $navbar-filter-bg;

      &:not([disabled]):hover {
        background-color: $navbar-filter-hover;
      }
      &.active {
        background-color: $navbar-main-bg;
      }
    }
  }
}

// Content padding fixes.
@media only screen and (min-width: $layout-breakpoint-md) {
  #main-content-wrapper,
  #top-people-selector,
  #top-group-selector {
    padding-left: var(--sidenav-width); // side toolbar width
  }
  .top-toolbar.fixed-toolbar > .md-toolbar-tools {
    padding-left: calc(var(--sidenav-width) + 16px); // side toolbar width + 16px padding
  }

  .wide-list {
    #main-content-wrapper,
    #top-people-selector,
    #top-group-selector,
    .top-toolbar.fixed-toolbar > .md-toolbar-tools {
      padding-left: 0;
    }
  }
}

@media only screen and (min-width: $layout-breakpoint-lg) {
  .wide-list {
    #main-content-wrapper,
    #top-people-selector,
    #top-group-selector {
      padding-left: var(--sidenav-width); // side toolbar width
    }
    .top-toolbar.fixed-toolbar > .md-toolbar-tools {
      padding-left: calc(var(--sidenav-width) + 16px); // side toolbar width + 16px padding
    }
  }
}

// Reset left padding if user is not authenticated yet, or isn't an admin - they'll never see the menu.
.no-left-nav {
  #main-content-wrapper,
  #top-people-selector,
  #top-group-selector,
  .top-toolbar.fixed-toolbar > .md-toolbar-tools {
    padding-left: 0;
  }
}
