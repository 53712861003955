/* Small, generic UI components */

// Status badges/labels
span.md-badge {
  min-width: 3rem;
  padding: 0 6px;
  margin-left: 14px;
  text-align: center;
  line-height: 22px;
  height: 22px;
  float: right;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  font-weight: 300;
  font-size: 0.8rem;
  color: #fff;
  background-color: paper-color($color-linced-blue, '500');
  border-radius: 2px;
}

// Drag handle for drag-and-drop sorting
.drag-handle {
  padding-right: 4px;

  md-icon {
    background-color: paper-color($color-linced-blue);
    color: #fff !important;
    border-radius: 50%;
    font-weight: 200;
  }
}

// Rows/sets of adjoining buttons
.adjoining-buttons {
  padding: 0 8px; // Account for the lack of left/right margin on adjoining buttons.

  .md-button,
  .md-button.md-raised {
    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
    min-width: 40px;

    &:first-child {
      border-radius: 2px 0 0 2px;
    }

    &:last-child {
      border-radius: 0 2px 2px 0;
    }
  }
}

.md-button.adjoining-first {
  border-radius: 2px 0 0 2px;
  margin-right: 0;
}

.md-button.adjoining {
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
}

.md-button.adjoining-last {
  border-radius: 0 2px 2px 0;
  margin-left: 0;
}

// Warning / error messages
.warning:not(md-toast) {
  background-color: paper-color($warn, '400');
  color: #fff;
  font-weight: bold;
  padding: 20px;

  md-icon {
    color: #fff;
  }
}

md-toast.warning .md-toast-content {
  background-color: paper-color($warn, '400');
  color: #fff;
}

// Success messages
.success:not(md-toast) {
  background-color: paper-color($success, '400');
  color: #fff;
  font-weight: bold;
  padding: 20px;

  md-icon {
    color: #fff;
  }
}

md-toast.success .md-toast-content {
  background-color: paper-color($success, '400');
  color: #fff;
}

// Informational messages
.info:not(md-toast) {
  background-color: paper-color($color-amber, '200');
  padding: 20px;
}

// Small messages
.warning.small,
.info.small,
.success.small {
  font-size: 80%;
  padding: 8px;
}

// Snazzy green buttons
.green,
.md-button.md-raised:not([disabled]).green,
.md-button.md-fab.green,
.md-button.md-fab:not([disabled]).green,
.md-button.md-icon-button.green,
.md-button.md-icon-button:not([disabled]).green {
  background-color: paper-color($success, '400');
  color: #fff;

  &:hover,
  &.md-focused {
    background-color: paper-color($success, '300');
  }

  md-icon {
    color: #fff;
  }
}

// Snazzy orange buttons
.orange,
.md-button.md-raised:not([disabled]).orange,
.md-button.md-fab.orange,
.md-button.md-fab:not([disabled]).orange,
.md-button.md-icon-button.orange,
.md-button.md-icon-button:not([disabled]).orange {
  background-color: paper-color($color-orange, '300');
  color: #fff;

  &:hover,
  &.md-focused {
    background-color: paper-color($color-orange, '200');
  }

  md-icon {
    color: #fff;
  }
}

md-icon.icon-green {
  color: paper-color($success, '400') !important;
}

md-icon.icon-warn {
  color: paper-color($warn, '400') !important;
}

md-icon.icon-blue {
  color: paper-color($color-blue, '400') !important;
}

// Round (circular) images, i.e. profile images
img.round,
.round img {
  border-radius: 50%;
  object-fit: cover;
}

// Generic clickable element
.clickable {
  cursor: pointer;
}

// Square and/or small icon buttons
.md-button.md-icon-button {
  &.square {
    border-radius: 2px;
    padding: 4px;
  }

  &.small {
    height: 28px;
    min-height: 28px;
    width: 28px;
    line-height: 28px;
    padding: 4px;
  }
}

// Dots with post counts in them
.post-count-dot {
  display: inline-block;
  background-color: paper-color($color-green, '500');
  color: #fff;
  border-radius: 50%;
  font-size: 0.8em;
  min-width: 14px;
  height: 14px;
  line-height: 14px;
  text-align: center;
}

// Navigation menu items
.md-sidenav-left {
  position: fixed !important;

  h3 {
    margin: 12px 0 0 13px;
  }

  .menu-item {
    font-size: 14px;
    font-weight: 500;

    .expand-icon {
      position: relative;
      right: -36px;
      transition: transform 250ms linear;
      pointer-events: none;
    }

    &.expanded .expand-icon {
      transform: rotate(-180deg);
    }
  }

  .submenu-item {
    font-size: 14px;
    font-weight: 500;

    .md-list-item-inner {
      margin-left: 28px;
      padding-left: 12px;
      border-left: 1px solid paper-color($color-grey, '300');
    }

    .md-button .md-list-item-inner {
      margin-left: 12px;
    }

    &--disabled,
    &--disabled md-icon {
      color: paper-color($color-grey, '400');
    }
  }

  .menu-item,
  .submenu-item {
    min-height: 40px;

    &:before {
      min-height: 40px;
    }

    &.active {
      background-color: paper-color($color-grey, '300');
    }

    .md-list-item-inner {
      min-height: 40px;

      &:before {
        min-height: 40px;
      }

      .layout-row > md-icon {
        margin-right: 4px;
      }

      .expand-icon {
        margin-right: 32px;
      }

      md-icon {
        margin: -3px 5px 0;
      }
    }
  }

  // Disabled menu items
  .menu-item-disabled,
  .menu-item-disabled md-icon {
    color: paper-color($color-grey, '400');
  }
}

// Profile banners
.profile-banner-card {
  background-color: paper-color($color-grey, '50');
  box-shadow: unset;
  margin-top: 0;
  padding-top: 1em;
  padding-bottom: 1em;

  // Shrink profile image on small screens
  @media (max-width: $layout-breakpoint-sm) {
    md-card-title {
      padding-left: 0;
    }
    md-card-title-media img.md-media-lg {
      width: 100px;
      height: 100px;
    }
  }
  @media (max-width: $layout-breakpoint-xs) {
    md-card-title {
      padding-top: 12px;
    }
    md-card-title-media img.md-media-lg {
      width: 60px;
      height: 60px;
    }
  }

  .md-subhead {
    font-weight: bold;
    padding-left: 1px;
  }

  .profile-banner__info {
    margin-top: 8px;

    md-icon {
      margin-right: 6px;
      margin-top: -1px;
    }

    &.critical {
      strong,
      md-icon {
        color: paper-color($warn, '400');
      }
    }
  }

  md-card-title {
    position: relative;
    padding: 24px 16px 6px;

    md-card-title-text {
      padding-left: 16px;
      margin-top: -14px;
      flex: none;
    }

    a.profile-banner__image {
      margin-top: -8px;
      position: relative;

      md-progress-circular {
        margin: 50px auto;
      }

      .uppy-DragDrop-container {
        display: block;
        position: absolute;
        z-index: 4;
        top: 0;
        left: 0;

        opacity: 0;

        .uppy-DragDrop-inner {
          padding: 12px;
        }
      }

      .uppy-DragDrop-container.is-dragdrop-supported.drag {
        opacity: 1;
        z-index: 6;
      }
    }

    .profile-banner__actions .md-button.md-icon-button {
      margin-right: 4px;
    }

    .ribbon-wrapper {
      top: 12px;
      left: 100px;
    }
  }
}

// paper-array-slider styles
md-slider[md-discrete]:not([disabled]).md-active .md-sign.md-sign-flexible {
  // Allow the tip to get wider than the default 28px if necessary
  width: auto;
  min-width: 28px;
  padding: 4px;

  // Align the middle of the tip with the correct point on the slider
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  // Move the arrow to the middle
  &:after {
    left: 50%;
    margin-left: -14px;
  }

  .md-thumb-text {
    margin: 4px;
    white-space: nowrap;
    text-align: center;
  }
}

// Icon picker
.icon-picker-container {
  padding-bottom: 16px;
}

.icon-picker {
  display: inline-block;
}

.md-button.icon-picker-button {
  min-width: 0;
  min-height: 0;
  line-height: normal;
  padding: 4px;
  margin: 4px;
  border: 1px dashed paper-color($color-grey, '500');

  &.icon-picker-selected {
    background: paper-color($color-grey, '300');
  }
}

.icon-picker-dialog {
  height: 75vh;

  md-input-container {
    margin-top: 0;
  }
}

/* Floating Action Button */

.fab-actions-container {
  display: flex;
  position: fixed;
  flex-direction: column;
  right: 0;
  bottom: 0;
  margin-right: calc(var(--safe-area-inset-right) * 0.75 - 10px);
  z-index: 22;
  align-items: center;
}

.fab-background.open {
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  pointer-events: inherit;
}

.fab-background {
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 21;
  pointer-events: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.fab-actions-deselecter.open {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.fab-input {
  position: absolute;
  opacity: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.new-post-fab.create {
  background-color: #febb35 !important;
}

.new-post-fab.attach {
  background-color: #4fc353 !important;
}

.new-post-fab.camera {
  background-color: #d00765 !important;
}

.new-post-fab.video {
  background-color: #54a0ff !important;
}

.new-post-fab.add {
  width: 65px;
  height: 65px;
  background-color: #22599e !important;
}

.new-post-fab:hover {
  transform: scale(0.95);
  transition: transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.new-post-fab:focus {
  transform: scale(0.9);
  transition: transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.new-post-fab.opened {
  transform: scale(1);
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.new-post-fab.closed {
  transform: scale(0);
  opacity: 0;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.new-post-fab.add.closed {
  pointer-events: inherit;
  opacity: 1;
  transform: rotate(0deg);
}

.new-post-fab.add {
  transform: rotate(45deg);
}

// Page Details modal dialog component
.page-details-dialog {
  min-height: 80vh;

  md-dialog-content {
    height: 100%;
  }
}

.page-details-media {
  height: auto;
  margin: 0 auto;
  max-width: 800px;

  img {
    max-height: 800px;
    object-fit: contain;
  }
}

// Don't stretch out drag handles in paper-items
md-list-item:not(.menu-item) .drag-handle md-icon {
  padding-right: 0;
}

// Sortable lists (dynamic fields).
.input-sortable-list {
  > h4 {
    margin-bottom: 4px;
  }

  .dragSortItem > .layout-row {
    padding: 4px 0;

    md-icon {
      // Add some horizontal space and nudge icons up slightly to align better
      // with accompanying text.
      margin: 0 4px 1px;
    }
  }
}

.md-dirty > .input-sortable-list > h4 {
  color: paper-color($color-amber, '700') !important;
}

.grade-assessment-overview {
  display: flex;

  button.md-icon-button {
    margin: 0;
  }
}

/**
 * post-filter provides component scoped CSS Styles for filtering
 * posts.
 */
.post-filter {
  // Global Overrides
  margin: 8px auto 0px auto;
  max-width: 616px;

  button {
    flex: 1;
  }

  md-card-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  // Elements
  &__author-filter,
  &__student-filter,
  &__group-filter {
    md-chips-wrap {
      padding-bottom: 4px;
    }

    md-contact-chips,
    md-chips {
      width: 100%;
    }

    .ember-paper-autocomplete-search-input {
      font-size: 16px;
    }

    .md-chip-input-container,
    md-chip {
      margin-top: 4px;
    }
  }

  &__button-row {
    padding: 5px;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    gap: 16px;
    justify-content: center;

    button {
      border-radius: 10px;
      margin: 8px 0 0;
    }
  }

  &__date-inputs {
    gap: 8px;

    md-input-container {
      margin-top: 12px;
    }
  }

  &__date-input {
    flex: 1;

    .md-errors-spacer {
      display: none;
    }
  }

  &__delete-button {
    max-width: 48px;
  }

  &__dropdown {
    margin-bottom: 16px;

    &--closed {
      display: none;
    }
  }

  &__filter-search-bar {
    display: flex;
    gap: 8px;

    button {
      border-radius: 10px;
      height: 50px;
      margin: auto auto auto 8px;
    }

    .search-bar {
      flex: 4;

      &__search-wrapper {
        display: flex;
        width: 100%;
      }

      &__search-input {
        flex: 7;
        padding-right: 8px;
      }

      &__search-button {
        flex: 3;
        border-radius: 10px;
        height: 30px;
        min-width: 70px;
        margin: auto auto auto auto;
        display: inline-block;
      }
    }
  }
}

.search-bar {
  margin: 0;

  &__search-wrapper {
    width: 100%;
  }

  &__search-input {
    margin: 0 8px;
    width: 100%;

    // Reset min-height on this element to allow the input element to go
    // below 28px in height.
    .md-errors-spacer {
      min-height: 0;
    }
  }
}

// TinyMCE icons, for use in instructions etc.
.tinymce-icon {
  // Overrides
  > svg {
    vertical-align: bottom;
  }
}

.snapshots-table {
  &__report {
    padding: 8px 0;

    &:not(:last-child) {
      border-bottom: 1px solid paper-color($color-grey, '300');
    }

    &__button {
      min-width: fit-content;
      line-height: 1.6;

      &.md-button.md-icon-button {
        margin-top: 8px;
      }

    }
  }

}

.snapshots {
  &__generate {
    &__container {
      padding: 8px;
      border: 1px solid paper-color($color-grey, '300');
      border-radius: 4px;
      margin: 16px 0;

      &__inner-row {
        display: -webkit-inline-flex;

        .md-button {
          //width
        }
      }
    }
  }
}
