.enrolment-form-builder {
  .md-list-item-inner {
    padding-left: 0;

    h4 {
      margin: 12px 0;
    }

    li > .md-button {
      margin: -4px 0 0 0;
      padding: 4px;
      min-width: 24px;
      width: 24px;
      min-height: 24px;
      height: 24px;
    }
  }
}

.application-form-data > div {
  padding: 3px 0;

  &:nth-child(odd) {
    background-color: paper-color($color-blue-grey, '50');
  }

  > strong,
  > span {
    padding: 0 3px;
  }
}
