// Colours for Comments statuses
$color-status-pending: paper-color($color-amber, '300');
$color-status-accepted: paper-color($color-green, '300');
$color-status-declined: paper-color($color-red, '500');
$color-status-declined-text: paper-color($color-red, 'contrastDefaultColor');

// Post lists
.post-list-heading {
  background: paper-color($color-grey, '50');
  position: sticky;
  top: -16px;
  max-width: 620px;
  padding: 0 8px 4px 8px;
  margin: 0 auto 18px auto;
  z-index: 2;
  color: paper-color($color-grey, '600');

  md-icon {
    width: 24px;
    margin: 0 6px;
    color: paper-color($color-grey, '600');
  }
}

.about-heading {
  md-icon {
    width: 24px;
    margin: 0 6px;
  }
}

md-card.page-description-card {
  max-width: 600px;
  margin: 16px auto;
}

.dummy-new-post {
  // The element inside this one needs to be max-width 600px with 8px margin on each side.
  // This element therefore needs a max-width of 600+8+8=616px.
  max-width: 616px;
  margin: 8px auto;
  md-chips-wrap {
    box-shadow: none;
  }
}

.post-container,
.summary-container {
  margin: 8px;
}

.new-post-dialog {
  border: 0px dashed paper-color($color-grey, '400');
  border-radius: 2px;
  color: paper-color($color-grey, '500');
  padding: 8px 14px;
  box-sizing: border-box;
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.21);
  border-radius: 10px;
  min-height: 50px;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
  -webkit-transition: all 0.05s ease-in-out;
  -moz-transition: all 0.05s ease-in-out;
  -o-transition: all 0.05s ease-in-out;
  transition: all 0.05s ease-in-out;
  font-weight: 400;
  font-size: 18px;
  display: flex;
}

.new-post-dialog:active {
  transform: scale(0.99);
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.05s ease-in-out;
  -moz-transition: all 0.05s ease-in-out;
  -o-transition: all 0.05s ease-in-out;
  transition: all 0.05s ease-in-out;
}

.post-content {
  height: 100%;
}

// New post interface
.newpost-ui {
  min-height: 50vh;

  .mce-tinymce {
    width: 100%;
  }
  .tox-edit-area {
    min-height: 30vh;
  }
}
md-dialog .md-dialog-content.newpost-dialog-content {
  padding: 12px 24px;
}
.post-tags-chooser {
  h4 {
    margin-bottom: 4px;
  }
  .md-chips.layout-column md-chip {
    float: none;
    cursor: pointer;
  }
  md-chip > md-checkbox {
    > .md-container {
      display: none;
    }
    > .md-label {
      margin-left: 0;
    }
  }
}

.md-button.md-default-theme.md-raised.new-post-fab,
.md-button.md-default-theme.md-raised.red-fab {
  background-color: paper-color($color-red, '700');

  &:hover {
    background-color: paper-color($color-red, 'A200');
  }
  md-icon {
    color: #fff;
  }
}

// Posts
md-card.post {
  max-width: 600px;
  margin: 16px auto;

  .post-header-text {
    max-height: inherit;
  }

  .post-avatar {
    height: 100%;
  }

  .post-author {
    width: 75px;
    height: 75px;

    img {
      margin: auto;
      width: 70px;
      height: 70px;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  @media only screen and (max-width: 350px) {
    md-card
      md-card-header
      md-card-avatar
      + md-card-header-text
      .md-title.post-title,
    .post-title {
      margin-top: 0 !important;
    }
    .post-author,
    .post-author img {
      margin-top: 1px;
      width: 35px;
      height: 35px;
    }
  }

  md-card md-card-header md-card-avatar + md-card-header-text {
    max-height: auto;
  }

  md-card
    md-card-header
    md-card-avatar
    + md-card-header-text
    .md-title.post-title,
  .post-title {
    font-size: 1.2em;
    margin: 12px 0 0 4px;
    padding-bottom: 0.1em;
  }

  .post-byline {
    margin-left: 4px;

    md-chip {
      background-color: paper-color($profile-banner-bg, '300');
      height: 24px;
      line-height: 24px;
      vertical-align: middle;
      border-radius: 12px;
      padding: 0;
      display: inline-block;

      .md-chip-content {
        display: inline-block;
        line-height: 24px;
        height: 24px;
        margin: 0 12px 0 6px;
        vertical-align: top;
      }
    }
    md-chips {
      .md-contact-avatar img {
        max-width: 24px;
        max-height: 24px;
      }
      md-chips-wrap {
        box-shadow: none;
        font-size: 0.9em;
      }
      md-chip .md-chip-content {
        margin-left: 0;
      }
    }
  }

  .post-type md-icon {
    width: 18px;
    margin-left: 4px;
  }

  md-card-actions {
    margin: 0;

    .md-button.md-icon-button {
      height: 24px;
      min-height: 24px;
      line-height: 24px;
      width: 24px;
      padding: 3px;

      md-icon {
        color: paper-color($color-grey, '400');

        &:hover {
          color: paper-color($color-grey, '600');
        }
      }
    }
  }

  // Post publishing widget/button
  .post-publisher {
    padding-left: 8px;
  }

  // Post body
  .post-body :first-child {
    // The md-card-content element already provides top padding, so we don't want top margin on the first
    // child element, as it would result in excessive whitespace.
    margin-top: 0;
  }

  .post-body {
    line-height: 23px;
    overflow: hidden;

    img {
      max-width: 100%;
    }
  }

  // Comments
  .post-comments {
    border-top: 1px solid paper-color($color-grey, '300');
    padding-top: 8px;
    font-size: 85%;

    .post-comments-count {
      cursor: pointer;

      md-icon {
        margin: 0 3px 0 6px;
      }
    }
  }
  .post-gallery {
    position: relative;
    max-height: 80vh;
    background-color: paper-color($color-grey, '300');
    overflow: hidden;

    img {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      max-height: inherit;
      max-width: 100%;
      margin: 0 auto;
      object-fit: cover;
      object-position: 50% 50%;
    }

    .video-js .vjs-big-play-button {
      left: 40%;
      width: 20%;
      top: 40%;
    }

    object,
    iframe {
      height: 50vh;
    }

    .post-gallery-prev,
    .post-gallery-next {
      position: absolute;
      top: 50%;
      margin-top: -16px;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }
    }

    .post-gallery-prev {
      left: 8px;
    }

    .post-gallery-next {
      right: 8px;
    }
  }
  .post-comment {
    margin: 8px 0;
    padding-top: 8px;

    img.comment-author {
      margin-right: 8px;
      width: 36px;
      height: 36px;
    }
    span.comment-author {
      margin-bottom: 8px;
      font-weight: bold;
    }
    .comment-in-reply-to {
      font-size: 85%;
      padding: 2px 0;

      md-icon {
        margin-top: -2px;
      }
    }
  }

  .post__comment {
    &--removed {
      opacity: 80%;
    }
  }

  .comment-date {
    margin: 8px 8px 8px 0px;
    color: paper-color($color-grey, '600');
  }

  .comment__status {
    cursor: default;
    min-width: 24px;
    height: min-content;
    margin: -2px 5px 0;
    padding: 0 8px;
    font-size: 0.8em;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 10px;
    line-height: 2;

    &--accepted {
      background-color: $color-status-accepted;
    }

    &--pending {
      background-color: $color-status-pending;
    }

    &--declined {
      background-color: $color-status-declined;
      color: $color-status-declined-text;
    }
  }
  .post-comments > .post-comment > .post-comment {
    // Indent second level (first level of replies) only.
    padding-left: 44px;
  }
  .post-new-comment {
    margin-top: 8px;
    padding-top: 8px;
    font-size: 85%;

    img.comment-author {
      margin-right: 8px;
      width: 36px;
      height: 36px;
    }

    md-input-container {
      margin-top: 0;
    }

    .md-button.md-icon-button {
      margin: 0 10px;
      padding: 0;
      width: 16px;
      min-height: 16px;
      height: 16px;
      line-height: 16px;
    }
  }

  .post-tags {
    margin-top: 16px;
  }

  md-card-content .post-tags {
    md-chip {
      cursor: pointer;
      &.md-selected {
        background-color: paper-color($color-teal, '300');
      }
    }
  }
}

// Post attachments
.post-attachment {
  height: 130px;
  width: 120px;
  margin: 6px 12px;
  position: relative;

  .uppy-DashboardItem-preview {
    height: 100px !important;
    width: 120px !important;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
    border: 1px solid paper-color($color-grey, '300');
    background-color: paper-color($color-grey, '300');

    .uppy-DashboardItem-previewInnerWrap {
      box-shadow: unset !important;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      > img {
        max-height: 90px;
        max-width: 120px;
      }
    }
    .uppy-DashboardItem-previewIcon {
      z-index: 1 !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  > span {
    font-size: 0.8em;
    white-space: nowrap;
    overflow: hidden;
    padding: 3px;
    box-sizing: border-box;
    width: 120px;
    text-overflow: ellipsis;
    border: 1px solid paper-color($color-grey, '300');
    border-top: 0px none;

    &.attachment-name {
      border-bottom: 0px none;
    }

    &.attachment-meta {
      font-size: 0.65em;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  button.md-fab.md-mini {
    position: absolute;
    right: -8px;
    top: -8px;
    padding: 2px;
    margin: 0;
    line-height: 22px;
    min-height: 22px;
    height: 22px;
    width: 22px;
  }
}

// Reactions (posts, comments)
.reactions {
  .reactions-counts {
    padding-right: 8px;
    font-size: 0.85em;

    img {
      vertical-align: middle;
      margin-left: 8px;
    }
  }

  .md-button.reactions-react {
    padding: 0;
    margin: 6px 0;
    min-width: 0;
    min-height: 26px;
    line-height: 24px;

    img,
    span {
      vertical-align: middle;
    }
    img {
      opacity: 0.8;
    }
    span {
      display: inline-block;
      padding-top: 2px;
      color: paper-color($color-grey, '500');
      text-transform: none;
    }
    &.reactions-reacted,
    &:hover {
      img {
        opacity: 1;
      }
      span {
        color: paper-color($color-grey, 'A200');
      }
    }
  }
}

// Post publishing UI
.post-publisher {
  .md-button.md-raised {
    color: paper-color($color-grey, '800');

    &[disabled] {
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);

      &.published {
        background-color: paper-color($color-green, '300');
        color: #fff;
      }
      &.draft {
        background-color: paper-color($color-orange, '300');
        color: #000;
      }
    }
  }
}

.modal-title {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  pointer-events: none;
}

.new-post-toolbar {
  display: flex;
  justify-content: space-between;
}

.new-post-actions {
  display: flex;
  justify-content: space-between;
}

/* Post Creation */

.mobile-post-container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.newpost-dialog-content {
  flex-grow: 1;
}

.newpost-post-body {
  position: relative;

  .newpost-editor-wrap {
    display: flex !important;
    flex-direction: column;
    flex: 1;
    align-items: stretch;

    .mce-tinymce,
    .mce-stack-layout,
    .mce-edit-area {
      display: flex !important;
      flex-direction: column;
      flex: 1;
      align-items: stretch;
    }
    .mce-tinymce iframe {
      flex: 1;
    }
  }
}

.mobile-input {
  width: 100%;
  height: 100% !important;
  font-size: 20px;
  border: 0;
}

.newpost-ui {
  height: 100%;
}

.newpost-form {
  height: 100%;
}

// Override Google Picker dimensions on mobile
@media only screen and (orientation: portrait) {
  .picker.picker-dialog {
    height: 80vh !important;
    width: 96vw !important;
    top: 0 !important;
    margin: 10vh 0;

    > .picker-dialog-content {
      height: 100% !important;
      width: 100% !important;
    }
  }
}

.mobile-post-toolbar {
  width: 100%;
  height: 100%;
  border-top: 1px solid #d7d7d7;
}

.post-calender {
  border-width: 0 0 1px 0;
  height: 31.3px;
  width: 250px;
  font-size: 95%;
}

.post-date-label {
  color: var(--foreground-2);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: small;
}

.post-filter-label {
  color: blue;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.post-filter-label:hover {
  fill-opacity: 0.75;
  cursor: pointer;
}

// Read tracking sentinels
.post-view-sentinel {
  height: 0;
  padding: 0;
  margin: 0;
}

// Highlight published (publishable) posts
.post-published {
  md-card {
    box-shadow: inset 6px 0 0 0 paper-color($success, '300'),
      0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  }
}
.post-draft {
  md-card {
    box-shadow: inset 6px 0 0 0 paper-color($color-orange, '300'),
      0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  }
}
.post-hero {
  md-card {
    box-shadow: inset 6px 0 0 0 paper-color($color-linced-blue, '500'),
      0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  }
}

.tag-wrap {
  height: max-content !important;
  line-height: normal !important;
}

.tag-text-padding {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.tag-text-wrap-checkbox {
  margin-bottom: 4px !important;
  margin-top: 4px !important;
}

.pinned-post {
  &__pinned-icon {
    height: 36px;
    display: flex;
    cursor: default;
  }
}
