// Override CSS in ember-cli-tinymce which sets display:none on the original (replaced) element
// as that prevents tinymce from calculating the correct height for the inner editor element.
body .tinymce-editor {
  display: initial;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

// Only show character count on focused or invalid fields (with maxlength)
.md-char-counter {
  display: none;
}
md-input-container.md-input-focused .md-char-counter,
md-input-container.md-input-invalid .md-char-counter {
  display: block;
}

md-chips.single-chip .md-chip-input-container > input {
  display: none;
  width: 3px;
}
md-chips.single-chip.md-input-has-value .md-chip-input-container > input {
  display: block;
}
md-chips.single-chip.md-input-has-value
  .md-chip-input-container
  > md-autocomplete {
  display: none;
}
// provides ellipsis for goal radial dial labels by limiting label width.
md-chips span.goal-details-label {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 110px;

  @media (max-width: $layout-breakpoint-xs) {
    max-width: 90px;
  }
}

// 'Remove' icons in chips should only be focusy when the chip itself is focused
md-input-container.md-default-theme:not(.md-input-invalid).md-input-focused
  md-chip:not(.md-focused)
  md-icon,
md-input-container:not(.md-input-invalid).md-input-focused
  md-chip:not(.md-focused)
  md-icon {
  color: $icon-base-color;
}
md-input-container.md-default-theme:not(.md-input-invalid).md-input-focused
  md-chip.md-focused
  md-icon
  md-input-container:not(.md-input-invalid).md-input-focused
  md-chip.md-focused
  md-icon {
  color: paper-color($primary, 'contrast');
}

// Prevent text from spilling out of chips.
.md-chips md-chip {
  overflow: hidden;
  text-overflow: ellipsis;
}

.chip-input-limit-reached {
  width: 20px;

  input {
    width: 100%;
  }
}

// Allow wrapping in switch labels
md-switch .md-label {
  white-space: normal;
  line-height: normal;
}

// Remove unnecessary bottom padding in autocomplete
md-autocomplete[md-floating-label] md-input-container {
  padding-bottom: 0;
}

// Remove unnecessary bottom margin on input containers (which mades forms very long)
md-input-container {
  margin-bottom: 0;
}

// Stop linky buttons from sticking out like sore thumbs
.md-button > a {
  color: currentColor;

  &:visited {
    color: currentColor;
  }
}

// Make loading spinners in buttons sit in the middle of the button
.md-button > md-progress-circular {
  margin: 0 auto;
}

.layout-column > small {
  padding: 4px 0;
}

md-table-container {
  // Due to macOS being dumb about scroll bar placement, lift up the last cell
  // so you can actually see the data!
  padding-bottom: 16px;
}

// Tighten up paper-data-tables
table.md-table.md-row-select td.md-cell:first-child,
table.md-table.md-row-select th.md-column:first-child {
  padding: 0 0 0 12px;
}
table.md-table.md-row-select td.md-cell:nth-child(2),
table.md-table.md-row-select th.md-column:nth-child(2),
table.md-table > tfoot.md-foot > tr.md-row > td:first-child {
  padding: 0 12px;
}
table.md-table.md-row-select td.md-cell:nth-child(n + 3):nth-last-child(n + 2),
table.md-table.md-row-select
  th.md-column:nth-child(n + 3):nth-last-child(n + 2) {
  padding: 0 28px 0 0;
}
table.md-table:not(.md-row-select) td.md-cell:first-child,
table.md-table:not(.md-row-select) th.md-column:first-child {
  padding: 0 12px;
}
table.md-table:not(.md-row-select)
  td.md-cell:nth-child(n + 2):nth-last-child(n + 2),
table.md-table:not(.md-row-select)
  th.md-column:nth-child(n + 2):nth-last-child(n + 2) {
  padding: 0 28px 0 0;
}
table.md-table th.md-column {
  font-size: 105%;
}

// Allow paper-data-table cells to be center-aligned if desired
table.md-table td.md-cell.center,
table.md-table th.md-column.center {
  text-align: center;
}
// Prevent "clear" buttons in paper-select fields from getting wide
.md-select-value > span:not(.md-select-icon).ember-power-select-clear-btn {
  max-width: 9px;
  margin: 0 8px;
}
.md-select-value > span.md-select-icon {
  max-width: 9px;
  margin: 0 8px;
}

// "spacer" rows in tables, which contain no data.
table.md-table tbody.md-body > tr.md-row.spacer {
  height: 8px;
  background-color: #fff;
}
// Hide "spacer" row if it's the last row of the last body in the table.
table.md-table tbody.md-body:last-of-type > tr.md-row.spacer:last-child {
  display: none;
}

// Boost ember-tooltip above dialogs
.ember-tooltip,
.ember-popover {
  z-index: 100;
}

// Make centred paper-switches in table cells actually centred.
.center md-switch.layout-align-center-center .md-container {
  margin-right: 0;
}

.md-button.md-fab.md-fab-bottom-right {
  position: relative;
}

// If the sidebar is locked open and the environment warning is showing, push
// it in by 320px to clear the navbar.
md-sidenav.md-locked-open + div#main .environment-warning {
  // push banner in due to locking md-sidenav.
  left: var(--sidenav-width);
}

// Input field tweaks.
md-input-container {
  // Allow for "hint" text below input fields.
  .hint {
    position: absolute;
    left: 2px;
    right: auto;
    bottom: 2px;
    font-size: 12px;
    line-height: 14px;
    -webkit-transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    color: paper-color($color-grey);
  }

  // Make invalid (required but empty) chips have a red bottom line.
  &.md-input-invalid > md-chips .md-chips {
    box-shadow: 0 2px #d50000;
  }
}

// Make disabled fields less greyed out.
.md-readonly md-input-container {
  .md-input[disabled],
  md-radio-button[disabled],
  md-checkbox[disabled] .md-label {
    color: paper-color($color-grey, '600') !important;
  }
  md-radio-button[disabled] .md-container {
    .md-off {
      border-color: paper-color($color-grey, '600');
      background-color: paper-color($color-grey, '300');
    }
    .md-on {
      background-color: paper-color($color-grey, '600');
    }
  }
  md-checkbox:not(.md-checked) .md-icon {
    border-color: paper-color($color-grey, '600');
    background-color: paper-color($color-grey, '300');
  }
  md-checkbox.md-checked .md-icon {
    border-color: paper-color($color-grey, '600');
    background-color: paper-color($color-grey, '600');
  }
  > md-chips .md-chips {
    box-shadow: none;
  }
}
.md-readonly > md-switch[disabled] {
  .md-bar {
    background-color: paper-color($color-grey, '300');
  }
  .md-thumb-container .md-thumb {
    background-color: paper-color($color-grey, '600');
  }
  &:not(.md-checked) .md-thumb-container .md-thumb {
    background-color: paper-color($color-grey, '400');
  }
  .md-label {
    color: paper-color($color-grey, '600');
  }
}
