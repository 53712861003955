// Category "badges" in subject admin pages
.md-headline > .goal-category-badge {
  margin-right: 12px;
}
.goal-category-badge-uploader {
  position: relative;
  width: 150px;
  height: 150px;
  margin-right: 12px;

  img {
    max-width: 150px;
    max-height: 150px;
  }

  .uppy-DragDrop-container {
    display: block;
    position: absolute;
    z-index: 4;
    top: 0;
    left: 0;

    opacity: 0;

    .uppy-DragDrop-inner {
      padding: 12px;
      line-height: 1.2;
    }
    .uppy-DragDrop-arrow {
      width: 50px;
      height: 50px;
      margin-bottom: 12px;
    }
  }
  &.is-empty:not(.is-uploading) .uppy-DragDrop-container,
  .uppy-DragDrop-container.is-dragdrop-supported.drag {
    opacity: 1;
  }
}

// Legend for goal subject flags (visibility, Milestones config)
.goal-subject-flags-legend {
  list-style-type: none;
  font-size: 80%;
  margin-top: 0;
  padding-left: 0;

  > li {
    padding-bottom: 2px;

    > md-icon {
      vertical-align: top;
    }
  }
}
