.md-chips.expectations {
  padding-bottom: 0;

  .well-below {
    background-color: paper-color($color-red, '300');
    color: #fff;
  }
  .below {
    background-color: paper-color($color-amber, '100');
    border: 1px solid paper-color($color-grey);
  }
  .at {
    background-color: paper-color($color-linced-blue, '500');
    color: #fff;
  }
  .above {
    background-color: paper-color($color-green, '400');
    color: #fff;
  }
  .well-above {
    background-color: paper-color($color-yellow, 'A700');
  }

  md-chip {
    background-color: paper-color($color-grey, '500');
    float: none;
    display: inline-block;
    margin: 4px 2px 8px 0;
  }
}
.expectations > .dragSortList {
  overflow: visible;
  display: inline-block;
  margin-right: 2px;
  min-width: 18px;
  height: 50px;
  text-align: center;

  &.-isExpanded.-isDraggingOver {
    border: 1px dashed paper-color($color-grey, '500');
  }
  &.-isExpanded.-isDraggingOver:before {
    // Hide placeholder arrow when dragging over an empty list
    display: none;
  }
}

.expectation-marker {
  display: inline-block;

  > div {
    position: relative;
    border: 1px dashed paper-color($color-grey, '600');
    width: 0;
    height: 48px;
    margin: 0 8px;

    // Triangles at top and bottom
    &:before,
    &:after {
      content: '';
      position: absolute;
      left: -6px;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
    }
    &:before {
      top: -1px;
      border-top: 6px solid paper-color($color-grey, '600');
    }
    &:after {
      bottom: -1px;
      border-bottom: 6px solid paper-color($color-grey, '600');
    }
  }

  // Hide placeholder arrow when dragging over a non-empty list
  &.dragSortItem.-placeholderAbove,
  &.dragSortItem.-placeholderBelow {
    padding-top: 0;
    padding-bottom: 0;
  }
  &.dragSortItem.-placeholderAbove:before,
  &.dragSortItem.-placeholderBelow:before {
    display: none;
  }
}
