/* Small, generic UI components */

// Inline action buttons - small, minimal padding.  For rows of related buttons, i.e. edit + delete
.inline-action-buttons {
  margin: 0 4px;

  .md-button {
    padding: 2px;
    margin: 0;
    min-height: 28px;
    height: 28px;
    line-height: 28px;
    min-width: 28px;
    width: 28px;
  }
}

// Top-aligned cell content in paper-data-table
.top-aligned-table > table.md-table td.md-cell {
  vertical-align: top;
}

// Coloured icons
md-icon.icon-red {
  color: paper-color($warn, '400');
}
md-icon.icon-green {
  color: paper-color($success, '400');
}

// Lists
.zebra-list > md-list-item:nth-of-type(odd),
.zebra-list > .draggable-object:nth-of-type(odd) > md-list-item,
.zebra-list > .dragSortList > .dragSortItem:nth-of-type(odd) > md-list-item,
.zebra-list > table > tbody > tr:nth-of-type(odd) {
  background-color: paper-color($color-linced-blue, '50');
}
.md-list-item-inner.md-no-style {
  padding: 8px 16px 0;
}

// Green "save" FAB
button.md-fab.save {
  background-color: paper-color($color-green, 'A700') !important;
}

ul.no-bullets {
  list-style-type: none;
  padding-left: 0;
}
ul.medium-indent {
  padding-left: 24px;
}

.max-cols-3 > * {
  margin: 8px;
  min-width: calc(33.3% - 16px);
}

.tall-dialog {
  height: 80vh;
}

.school-logo-uploader {
  position: relative;
  width: 150px;
  height: 150px;

  img {
    max-width: 150px;
    max-height: 150px;
    margin: 0 auto;
  }

  .uppy-DragDrop-container {
    display: block;
    position: absolute;
    z-index: 104;
    top: 0;
    left: 0;

    opacity: 0;

    .uppy-DragDrop-inner {
      padding: 12px;
      line-height: 1.2;
    }
    .uppy-DragDrop-arrow {
      width: 50px;
      height: 50px;
      margin-bottom: 12px;
    }
  }
  &.is-empty:not(.is-uploading) .uppy-DragDrop-container,
  .uppy-DragDrop-container.is-dragdrop-supported.drag {
    opacity: 1;
  }
}

// Editable list built up of multiple inputs and buttons etc
.input-editable-list {
  // Remove space below inner fields
  md-input-container > .md-errors-spacer {
    display: none;
  }

  // Prevent drag handle icons from becoming the same colour as their background when field is focused
  &:not(.md-input-invalid).md-input-focused .drag-handle md-icon,
  &.md-default-theme:not(.md-input-invalid).md-input-focused
    .drag-handle
    md-icon {
    color: #fff;
  }

  // Push drag handle up off the bottom to align better with field value
  .drag-handle {
    margin-bottom: 8px;
  }

  // Remove excessive margins on "Add item" heading
  h5 {
    margin-top: 8px;
    margin-bottom: 0;
    padding-left: 24px;
  }
  .spacer {
    width: 24px;
  }
}

// Square and/or small icon buttons
.md-button.md-icon-button {
  &.square {
    border-radius: 2px;
    padding: 4px;
  }

  &.small {
    height: 28px;
    min-height: 28px;
    width: 28px;
    line-height: 28px;
    padding: 4px;
  }
}

.monospaced-country-code {
  display: inline-block;
  width: 2em;
  text-align: left;
}

// Tables with alternating background colours
.zebra {
  table.md-table {
    thead.md-head > tr.md-row,
    tfoot.md-foot > tr.md-row {
      background-color: paper-color($color-linced-blue, '200');

      th.md-column {
        background-color: inherit;
      }

      &.field-group-headers {
        background-color: paper-color($color-linced-blue, '500');
        color: #fff;
      }
    }
  }

  tbody > tr:nth-of-type(even) {
    background-color: paper-color($color-grey, '100');
  }
}

table.md-table {
  thead.md-head > tr.md-row,
  tfoot.md-foot > tr.md-row {
    height: 36px;

    th.md-column {
      background-color: inherit;

      md-icon {
        height: 16px;
        min-height: 16px;
        width: 16px;
        min-width: 16px;
        font-size: 16px !important;
        line-height: 16px !important;
      }
    }

    &.field-group-headers {
      background-color: paper-color($color-linced-blue, '500');
      color: #fff;
    }
  }
  .border-left {
    border-left: 1px solid paper-color($color-grey, '400');
    padding-left: 8px !important;
  }
  .field-filters {
    td.md-cell {
      // Uniform right-padding on cells containing filter boxes
      padding-right: 12px !important;

      > md-input-container {
        // Filter boxes should fill the cell
        width: 100%;
        margin: 0;
        padding-left: 28px;

        // Icons should be small (to match smaller input-within-table-cell) and light grey when unfocused
        > md-icon {
          font-size: 18px;
          max-height: 18px;
          max-width: 18px;
          height: 18px;
        }
        &:not(.md-focused) > md-icon {
          color: paper-color($color-grey, '400');
        }

        // Has a value (and isn't invalid)
        &.md-input-has-value:not(.md-input-invalid) {
          md-icon {
            color: paper-color($color-green, '500');
          }
          .md-input {
            border-color: paper-color($color-green, '500');
            border-width: 0 0 2px 0;
            padding-bottom: 0;
          }
        }

        // No gap to fit error messages into, unless we have errors to display
        .md-errors-spacer {
          display: none;
        }
        &.md-input-invalid .md-errors-spacer {
          display: block;
        }
      }
    }
  }
}

.new-connection-dialogue {
  height: 80vh;
  &--wonde-search {
    width: 100%;
    md-input-container {
      width: 30%;
      max-width: 30%;
    }
  }
  &--school-table {
    table.md-table.md-row-select td.md-cell:first-child {
      width: 25%;
    }
  }
  &--connection-details {
    &--card {
      width: 45%;
    }
  }
  .layout-row.margin-top {
    margin-top: 16px;
  }
}

.wonde-sync-overview {
  &--dialog-content {
    position: relative;
    display: flex;
    flex-direction: row;
    &--card {
      max-height: 75vh;
      &--scroll-view{
        max-height: 75vh;
        height: 75vh;
      }
    }
    &--container {
      width: 100%;
      &__wide {
        min-width: 50%;
      }
    }
  }
}

.dropdown-multiselect{
  md-list {
    flex-direction: column;
    flex-wrap: wrap;
    display: flex;
    max-height: 100vh;
    width: 100%;
  }
  md-list-item {
    flex: 0 0 0;
  }
}

.page-templates__usage-sets {
  .md-chips {
    display: flex;
  }
}

.md-chips {
  md-icon {
    color: inherit;
  }
}

.school-wizard {
  &__checkbox {
    .md-container {
      top: 0;
      transform: none;
    }
    md-icon {
      margin: 0 0 5px 0;
    }
    &--select-all {
      margin-bottom: 0;
    }
  }
  &__step {
    position: relative;
    overflow-x: hidden;
  }
  &__sections {
    position: absolute;
    min-width: 97%;
  }
  &__divider {
    margin-bottom: 20px;
  }
  &__usage-set {
    &__chips {
      font-size: 14px;
    }
    &__chip {
      height: inherit !important;
      line-height: inherit !important;
      margin-top: 0 !important;
      margin-bottom: 8px !important;
      padding: 3px 12px 3px 12px !important;
    }
  }
}

#fab-destination .assessments__speed-dial {
  right: 50px;
  &__icon {
    margin: unset;
  }
  button {
    width: 130px;
    border-radius: 36px;
  }
}
.usage-sets {
  &__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  &__card {
   &__title {
    flex: 0 1 0;
   }
  }
}

.list-column-wrap {
  flex-direction: column;
  flex-wrap: wrap;
  display: flex;
  max-height: 100vh;
  width: 100%;
}

.tree-branch {
  &__inner {
    width:100%;
  }
  .drag-handle {
    margin: auto;
  }
}

.tree-node {
  &__inner {
    margin-left: 15px;
    width: 100%;
  }
  &__label {
    &--clickable {
      cursor: pointer;
      color: -webkit-link;
    }
  }
}

.padding {
  &--bottom {
    padding-bottom: 16px;
  }
}

.select-dialogue {
  &__backdrop {
    z-index: 100 !important;
  }
  &__container {
    z-index: 101;
  }
}
