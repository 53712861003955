/**
 * Colours for Goal States.
 */
$color-state-not-set: #000;
$color-state-developing: paper-color($color-amber, '100');
$color-state-developing-text: paper-color($color-amber, '400');
$color-state-secure: paper-color($color-green, '400');

md-card.goal-card {
  width: 95%;
  height: 194px;
  min-width: 240px;
  position: relative;
  font-size: 0.8em;
  // Interactive goal-cards contain an <a> which needs styling
  a {
    cursor: pointer;
    min-height: 64px;
  }

  // Non-interactive goal-cards still need space for goal-actions, but don't have the <a> tag
  > md-card-content {
    height: 159px;
    margin-right: 32px;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  md-progress-linear {
    position: absolute;
    bottom: 0;
    height: 12px;

    > div,
    .md-bar {
      height: 11px;
    }
  }

  .goal-actions {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 28px;
    height: 33px;
    border-top: 1px solid paper-color($color-grey, '200');
    display: flex;

    .md-button {
      padding: 2px;
      margin: 0;
      min-height: 28px;
      height: 33px;
      line-height: 30px;
      min-width: 28px;
      width: 28px;
      font-size: smaller;
    }
  }

  &.goal-card-detailed {
    padding-bottom: 20px;

    .goal-subject {
      font-size: 1em;
    }

    .goal-actions {
      top: auto;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      background-color: #fff;
    }
  }
}

.goal-assessor {
  padding-right: 16px;

  img {
    width: 48px;
    height: 48px;
    border: 1px solid paper-color($color-grey, '500');
  }
}

.goal-developing {
  background-color: $color-state-developing;
}

.goal-developing-text {
  background-color: $color-state-developing-text;
}

.goal-secure {
  background-color: $color-state-secure;

  md-card-content {
    color: #fff;
  }
}

.goals-legend {
  min-width: 24px;
  min-height: 24px;
  margin: -2px 2px 0;
  padding: 4px 8px;
  font-size: 0.8em;
  box-sizing: border-box;
  border: 1px solid paper-color($color-grey, '400');

  &.goal-expectation {
    border: 1px dashed paper-color($color-linced-blue);
  }

  &.goal-not-set.goal-progressions {
    background-color: paper-color($color-grey, '300');
  }
}

.goal-path {
  color: paper-color($color-grey);
}

// Progress bars inside category buttons
md-progress-linear.goal-progress {
  background-color: paper-color($color-grey, '400');
  border: 1px solid paper-color($color-grey, '400');
  border-bottom: 0 none;
  margin-top: -6px;

  .md-bar1 {
    background-color: paper-color($color-amber, '100');
  }

  .md-bar2 {
    background-color: paper-color($color-green, '400');
  }
}

// Flat list of (usually current) goals for a Learner
.goals-list {
  .goals-list-item {
    margin: 4px 0;

    .goal-assessor img {
      width: 42px;
      height: 42px;
    }
  }
}

//// Badgers
.goalpicker-buttons button img {
  margin-bottom: -5px;
  max-height: 21px;
  max-width: 21px;
}

h3 img.badge {
  margin-bottom: -3px;
  max-height: 21px;
  max-width: 21px;
}

.goal-picker-badges {
  margin-top: 10px;

  div {
    margin: 10px;
    text-align: center;

    div {
      margin: 0px;
    }
  }

  img {
    max-height: 84px;
    max-width: 84px;
  }
}

.goal-picker-badges-assessor {
  font-weight: bold;
}

.profile-banner-badges-sticky {
  margin: 4px 0px 0px 20px;

  img {
    max-height: 32px;
    max-width: 32px;
    cursor: pointer;
  }

  md-icon {
    cursor: pointer;
  }
}

.profile-banner-badges {
  margin-top: 8px;

  img {
    // Ensure buttons are left aligned against the visible line.
    margin: 4px 8px 4px 0px;
    max-height: 64px;
    max-width: 64px;
    cursor: pointer;
  }

  .md-button {
    // reset button margins/padding in order to align with the badges.
    margin: 4px 0px;
    padding: 0px;
  }

  .md-button.md-icon-button {
    // make the icon the same size as the badges.
    width: 32px;
  }

  &--sm {
    // provide small switch to enable smaller badges for inline display.
    img {
      max-height: 32px;
      max-width: 32px;
    }
  }
}

.badge-dialog-badge {
  margin: 42px 16px 0px 0px;

  img {
    max-height: 84px;
    max-width: 84px;
  }
}

.badge-dialog-assessor {
  margin-left: 10px;

  img {
    max-height: 42px;
    max-width: 42px;
    margin: 10px;
  }
}

.badge-dialog-content {
  div {
    margin-top: 11px;

    div {
      font-weight: bold;
      margin-right: 32px;
    }
  }
}

//// End Badgers
.example,
.md-button.md-raised:not([disabled]).example,
.md-button.md-icon-button.example,
.md-button.md-icon-button:not([disabled]).example {
  background-color: #07667d;
  color: #fff;

  &:hover,
  &.md-focused {
    background-color: #07667d;
  }

  md-icon {
    color: #fff;
  }
}

md-dialog-content {
  padding: 0;

  .example-card {
    text-align: center;
    min-height: 295px;
    position: sticky;
    top: -180px;
  }
}

//// Goals progress
.goals-progress {
  &__container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
    grid-column-gap: 20px;
  }
}

// Subject Type chips

.hero-purple {
  background-color:paper-color($color-linced-purple, '900');
  color: #FFF;
}
.hero-blue {
  color: #FFF;
  background-color: paper-color($color-linced-blue, '500');
}
.hero-light-blue {
  color: #000;
  background-color: paper-color($color-linced-light-blue, '500');
}
.hero-orange {
  color: #fff;
  background-color: paper-color($color-linced-orange, '900');
}
.hero-teal {
  color: #000;
  background-color: paper-color($color-linced-teal, '900');
}
.hero-dark-green {
  color: #fff;
  background-color: paper-color($color-linced-dark-green, '900');
}
