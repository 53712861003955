body {
  background-color: #fafafa;
}

// Account for notches / oddly-shaped screens.  Also sets main content area background colour.
:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
  --safe-area-inset-right: 0px;
}

:root {
  --vh-offset: 0px;
  --sidenav-width: 320px;
  --box-shadow: #00000080;
}


// Browsers which partially support CSS Environment variables (iOS 11.0-11.2).
@supports (padding-top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
    --safe-area-inset-right: constant(safe-area-inset-right);
  }
}

// Browsers which fully support CSS Environment variables (iOS 11.2+).
@supports (padding-top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
    --safe-area-inset-right: env(safe-area-inset-right);
  }
}

#main-content-wrapper {
  margin-top: calc(56px + (var(--safe-area-inset-top) * 0.75)); // toolbar height plus safe area for mobile
}

#main-content {
  background-color: paper-color($color-grey, '50');
}

.page-header-sticky {
  padding-top: calc((var(--safe-area-inset-top) * 0.75));
  padding-right: 120px;
  padding-left: 120px;

  &--on-nested-route {
    padding-right: 16px;
  }
}

@media (max-width: $layout-breakpoint-xs) {
  .page-header-sticky {
    padding-left: 64px;
  }
}

md-dialog {
  scrollbar-width: none;

  md-toolbar {
    padding-top: calc((var(--safe-area-inset-top) * 0.75));
  }

  md-toolbar {
    padding-left: calc(var(--safe-area-inset-left) * 0.5);
    padding-right: calc(var(--safe-area-inset-right) * 0.5);
  }

  md-dialog-actions {
    padding-left: calc(var(--safe-area-inset-left) * 0.5 + 16px);
    padding-right: calc(var(--safe-area-inset-right) * 0.5 + 8px);
  }

  md-dialog-content {
    padding-left: calc(var(--safe-area-inset-left) * 0.5);
    padding-right: calc(var(--safe-area-inset-right) * 0.5);
  }
}

// When the backdrop appears, we are showing full-screen content, so we should disable body scrolling.
body.md-opaque {
  overflow: hidden;
}

// Position dialogs correctly
md-backdrop,
.md-dialog-container {
  position: fixed !important;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

md-backdrop {
  z-index: 61 !important;

  &.md-sidenav-backdrop {
    z-index: 51 !important;
  }
}

.md-dialog-container {
  z-index: 62 !important;
}

.sidebar-content {
  margin-top: calc(var(--safe-area-inset-top) + 56px);
  margin-left: calc(var(--safe-area-inset-left) * 0.75);
}

.tab-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 25;
}

.navigation-safe-area {
  height: var(--safe-area-inset-top);
  background-color: #673ab7;
  position: fixed;
  top: 0;
  z-index: 22;
  right: 0;
  left: 0;
}

.bottom-safe-area {
  height: var(--safe-area-inset-bottom);
  background-color: #673ab7;
  position: fixed;
  bottom: 0;
  z-index: 22;
  right: 0;
  left: 0;
}

.fixed-toolbar {
  margin-top: calc(var(--safe-area-inset-top) * 0.75);
  -ms-touch-action: none !important;
  touch-action: none !important;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  height: 56px;
  min-height: 56px;
  max-height: 56px;
  z-index: 25;
}

.read-more-button {
  background-color: transparent;
  border: 0px solid transparent;
  color: #5f8bf5;
  font-weight: 500;
  margin-bottom: 0.5em;
  outline: 0;
}

.read-more-button button:focus {
  outline: 0;
}

@media (max-width: 959px) {
  md-dialog.md-dialog-fullscreen {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .md-dialog-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .uppy-Dashboard-inner {
    margin-top: 30px;
  }
}

md-sidenav {
  z-index: 60;
}

.mobile-input textarea {
  border: 0;
}

md-sidenav md-toolbar {
  position: fixed;
  height: calc((var(--safe-area-inset-top) * 0.75) + 56px);
  min-height: 56px;
  max-height: calc((var(--safe-area-inset-top) * 0.75) + 56px);
}

#main md-toolbar {
  position: fixed;
  margin-bottom: calc(var(--safe-area-inset-bottom) * 0.75);
}

// Don't fixed-position dialogs' toolbars, as that causes them to overlap the dialog's content.
#main md-dialog > md-toolbar {
  position: relative;
}

.sidenav-header {
  padding-top: calc(var(--safe-area-inset-top) * 0.75);
}

md-card {
  border-radius: 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.21);
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

md-card:active {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

a {
  text-decoration: none;
}

.center {
  text-align: center;
}

.icon-padding {
  padding: 4px;
}

@media only screen and (min-width: 1280px) {
  #main-content-wrapper {
    padding-left: var(--sidenav-width); // the size of the left sidebar
  }

  .page-header-sticky {
    padding-left: var(--sidenav-width);
  }
}

// Enforce some space at the bottom of the main content area so that it's possible to scroll content out
// from behind FABs
#main-content-wrapper {
  padding-bottom: 96px;
}

@media (max-width: $layout-breakpoint-sm) {
  #main-content-wrapper {
    padding-bottom: 72px;
  }
}

// Profile menu
.md-button.profile-menu-button {
  height: 36px;
  border-radius: 50%;

  img {
    border-radius: 50%;
  }
}

.profile-image-container {
  width: 36px;
  height: 36px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 8px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}

.profile-image {
  display: block;
  width: 100%;
}

.profile-menu-content {
  margin-right: 10px;
  top: 64px !important;

  md-menu-content {
    padding: 8px;
  }

  .profile-menu-avatar {
    width: 120px;
    height: 120px;
    object-fit: cover;
  }

  @media (max-width: $layout-breakpoint-xs) {
    .profile-menu-avatar {
      width: 80px;
      height: 80px;
    }
  }

  .profile-actions {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }

  .profile-button {
    text-align: left;
  }

  .profile-menu-name {
    font-weight: bold;
    font-size: 1.2em;
  }

  .profile-menu-name,
  .profile-menu-username {
    padding: 10px 14px 0px 14px;
  }
}

// Controls fixed at the bottom of a content area
.has-fixed-bottom {
  position: relative;
  padding-bottom: 64px;
  min-height: 100%;
  box-sizing: border-box;

  > :first-child {
    margin-top: 0;
  }

  .fixed-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

// Printing overrides
@media print {
  // Print all content - don't limit it to one page.
  div.site-nav-container {
    overflow: visible !important;
  }
  // Remove subtle gray background on main content area.
  #main-content {
    background-color: transparent;
  }
  // Try not to have page breaks inside Paper Cards.
  md-card {
    page-break-inside: avoid;
    box-shadow: none !important;
    max-width: none !important;
    width: 100% !important;
  }

  // Print-only content.
  .hide.show-print {
    display: initial !important;
  }
  // Make links black
  a {
    color: #000;
  }
  // A wee border for student images
  .profile-banner__image img.round,
  .round img {
    border: rgba(0, 0, 0, 0.87);
    border-style: solid;
    border-width: 1px;
    height: 100px !important;
    width: 100px !important;
    margin-left: 10px;
  }

  md-toolbar,
  .page-header-sticky,
  .access-requests-wrap {
    display: none !important;
  }

  #main-content-wrapper {
    margin-top: 0px !important;
  }

  .print-banner {
    height: 120px;
    margin-bottom: 2px;
    width: 100%;
    border-bottom: 1px solid grey;
  }

  .print-logo {
    position: absolute;
    top: 5px;
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

  .print-name {
    position: absolute;
    left: 150px;
    top: 12px;
  }

  .profile-banner-card {
    position: absolute;
    left: 0px;
    top: 0px;
  }
  .sticky-element--sticky {
    position: initial !important;
    top: 0;

    &:before {
      display: none;
    }
  }

  .profile-banner.sticky-element--sticky .profile-banner,
  .profile-banner.group-banner {
    box-sizing: border-box;
    min-height: 36px;
    height: 36px;
    position: relative;

    .profile-banner__image {
      margin: 0 !important;
      padding-top: 0;
      height: 150px !important;
      width: 150px !important;

      > img {
        height: 150px !important;
        width: 150px !important;
      }
    }

    md-card-title-text {
      margin-top: -54px !important;
      padding-left: 42px !important;
    }

    .profile-banner__actions {
      height: initial !important;
      margin-top: initial !important;
    }
  }

  .dummy-new-post,
  .hide-print,
  .hide-xs,
  .md-button,
  .post-comments,
  .profile-banner__info,
  .post-attachments,
  .ember-tooltip {
    display: none;
  }

  .profile-banner-card {
    border: 2px transparent !important;
    box-shadow: none;
    -webkit-box-shadow: none;
    background-color: white !important;
  }

  .otj-widget {
    display: none;
  }

  .post-list-heading {
    max-width: none !important;
    background: transparent !important;
    justify-content: left;
    position: absolute !important;
    top: 28px !important;
    left: 150px !important;
  }

  .post-list-heading > .md-font {
    display: none;
  }

  md-card-title,
  #main,
  #main-content,
  .profile-banner,
  .profile-banner,
  .post-gallery,
  .show-print {
    background-color: white !important;
  }

  md-card-title {
    padding-top: 16px !important;
    padding-bottom: 0px !important;
  }

  .md-card-image,
  .js-reframe,
  .post-gallery {
    max-height: 400px !important;
  }

  .md-chips md-chip {
    border-width: 1px;
    border-color: #e0e0e0;
    border-style: solid;
  }

  .home-page .print-hide-post {
    display: none;
  }

  .adjoining-buttons .md-button.md-primary.md-raised,
  .adjoining-buttons .md-button.md-primary.md-fab {
    box-shadow: inset 0px 0px 0px 20px #01667d;
    margin-right: 4px !important;
    display: initial !important;
  }

  md-tabs {
    display: none;
  }

  form .show div.ember-view {
    page-break-inside: avoid;
  }

  md-autocomplete-wrap button {
    display: none !important;
  }

  md-radio-button .md-on {
    box-shadow: inset 0px 0px 0px 10px rgba(1, 102, 125, 0.87);
  }

  .md-thumb:before {
    content: '\2718' !important;
    color: black;
    font-weight: bolder;
    margin-top: -13px;
    margin-left: 4px;
  }

  md-switch.md-checked:not([disabled]) .md-thumb:before {
    content: '\2714';
  }

  .progress-chart-legend {
    justify-content: left !important;
  }

  .profile-banner {
    padding-bottom: 100px;
  }

  .md-card-image {
    max-width: 480px !important;
    max-height: 360px !important;
    margin: 0px !important;
  }

  .goals-list .goals-list-item .goal-assessor img {
    width: 21px !important;
    height: 21px !important;
  }

  .profile-banner md-card-title-text {
    position: absolute;
    left: 150px;
    width: 100%;
  }
  .profile-banner md-card-title-text.print-position-wide {
    position: absolute;
    left: 250px;
    width: 100%;
  }
  .tags-overview-chart {
    background-color: white !important;
  }

  md-card.post .post-author img {
    width: 36px !important;
    height: 36px !important;
    margin-top: 18px !important;
    border: 1px solid #9e9e9e;
  }
  .md-subhead {
    min-width: 500px !important;
  }
  .md-subhead,
  h2 {
    color: black !important;
  }

  md-card.post .post-author {
    width: 38px !important;
    height: 38px !important;
  }

  .page-goals-card svg.chart-stacked-area {
    max-width: 480px;
    max-height: 360px;
  }

  md-card md-card-content > p:first-child {
    margin-top: 16px !important;
  }

  .post-container iframe {
    max-width: 480px;
    max-height: 360px;
    border-width: 0px;
  }
}

// "snug" headings - tighter top/bottom margins than the default
h1.snug,
h2.snug,
h3.snug,
h4.snug,
h5.snug {
  margin: 4px 0;
}

// Content boxes with maximum widths (centred)
.constrain-xs {
  max-width: 600px;
  margin: 16px auto;
}

.constrain-sm {
  max-width: 960px;
  margin: 16px auto;
}

.constrain-md {
  max-width: 960px;
  margin: 16px auto;
}

/* Page Header */
// md-toolbar {
//   position: fixed;
// }

.page-header {
  height: 2em;
  background-color: #673ab7;
  padding-bottom: 0.4em;
  font-size: 2em;
  display: flex;
  align-items: flex-end;
  padding-left: 0.5em;
  font-weight: 600;
  color: #fff;

  &.hidden > .page-header-title-container {
    opacity: 0;
  }
}

.page-header-title-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto;

  md-icon {
    color: #fff;
    margin-top: -6px;
  }
}

.page-header-sticky {
  min-height: 46px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 30;
  height: 56px;
  pointer-events: none;
}

.layout-column > .flex {
  min-height: auto;
}

.page-header-sticky.hidden {
  opacity: 0;
  pointer-events: none;
}

.page-header-title {
  opacity: 1;
  font-size: 1.2em;
  font-weight: 600;
  color: #fff;
  transform: translateY(0);
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  md-icon {
    margin-right: 2px;
    margin-top: -4px;
    color: #fff;
  }
}

.page-header-title.hidden {
  opacity: 0;
  transform: translateY(1em);
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.reaction-icon {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.reaction-icon:hover {
  transform: scale(1.1);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

/* Progress Indicator */

.indicator-backdrop {
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
}

.indicator-backdrop.open {
  opacity: 100;
  background-color: rgba(0, 0, 0, 0.8);
}

.indicator-container {
  background-color: #fff;
  border-radius: 20px;
  min-width: 100px;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.progress-text {
  margin-top: 10px;
  font-weight: 600;
  letter-spacing: 0.2px;
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #000; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 0.9s ease-in-out infinite;
}

.offline-splash {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  flex-direction: column;
  opacity: 0;
  pointer-events: none;
  z-index: -100;
}

.offline-splash.showing {
  opacity: 1;
  pointer-events: inherit;
  z-index: 1000;
}

.offline-image {
  height: 80px;
}

.offline-text {
  padding-top: 3em;
}

.offline-retry {
  margin-top: 5em;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.flatpickr-calendar {
  width: 250px !important;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #01667d !important;
}

flatpickr-calendar.arrowBottom:after {
  border-top-color: #01667d !important;
}

.flatpickr-months .flatpickr-month {
  background: #01667d !important;
}

.flatpickr-current-month {
  font-size: medium !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: #01667d !important;
}

.flatpickr-current-month
.flatpickr-monthDropdown-months
.flatpickr-monthDropdown-month {
  background-color: #01667d !important;
}

.flatpickr-weekdays {
  background: #01667d !important;
  height: 24px !important;
}

span.flatpickr-weekday {
  font-size: 80% !important;
  background: #01667d !important;
  color: rgba(255, 255, 255, 1) !important;
}

.flatpickr-days {
  width: 250px !important;
}

.dayContainer {
  width: 250px !important;
  min-width: 250px !important;
  max-width: 250px !important;
}

.flatpickr-day {
  width: 12px !important;
  max-width: 32px !important;
  height: 24px !important;
  line-height: 24px !important;
}

.flatpickr-day.endRange.nextMonthDay {
  background: #01667d !important;
  border-color: #01667d !important;
}

.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  -webkit-box-shadow: -10px 0 0 #01667d !important;
  box-shadow: -10px 0 0 #01667d !important;
}

.flatpickr-day.week.selected {
  -webkit-box-shadow: -5px 0 0 #01667d, 5px 0 0 #01667d !important;
  box-shadow: -5px 0 0 #01667d, 5px 0 0 #01667d !important;
}

.flatpickr-innerContainer {
  height: 150px !important;
}

.flatpickr-time input {
  font-size: 12px !important;
}

.no-border {
  border: none;
}

.no-touch-action {
  touch-action: none;
}

.callback-spinner {
  height: 100%;
  width: 100%;
  min-height: 100%;
  margin: -64px 0 0 -16px;
  position: fixed;
}

// Account settings

.account-settings {
  &__heading {
    margin-top: 16px;
    margin-bottom: 4px;
    margin-left: 8px;
  }

  &__devices > div {
    margin-top: 0px;
  }
}

.no-margins {
  margin: 0;
}

.bordered {
  img {
    border: paper-color($color-linced-blue) 2px solid;
  }
}

.display-flex {
  display: flex;
}

#mapContainer {
  width: 100%;
  height: 300px;
  content: '';
  margin: 20px 0;
}
