// Utility Color Classes

$light-contrast-color: rgba(255, 255, 255, 0.87) !default;
$dark-contrast-color: rgba(0, 0, 0, 0.87) !default;
$strong-light-contrast-color: rgb(255, 255, 255) !default;

$foreground-light: (
  '1': rgba(255, 255, 255, 1),
  '2': rgba(255, 255, 255, 0.7),
  '3': rgba(255, 255, 255, 0.5),
  '4': rgba(255, 255, 255, 0.12),
  'shadow': '1px 1px 0px rgba(0, 0, 0, 0.4), -1px -1px 0px rgba(0, 0, 0, 0.4)',
) !default;

$foreground-dark: (
  '1': rgba(0, 0, 0, 0.87),
  '2': rgba(0, 0, 0, 0.54),
  '3': rgba(0, 0, 0, 0.38),
  '4': rgba(0, 0, 0, 0.12),
  'shadow': none,
) !default;

$color-red: (
  '50': #fae4e5,
  '100': #f2bcbe,
  '200': #ea8f93,
  '300': #e26268,
  '400': #db4147,
  '500': #d51f27,
  '600': #d01b23,
  '700': #ca171d,
  '800': #c41217,
  '900': #ba0a0e,
  'A100': #ffe5e6,
  'A200': #ffb3b3,
  'A400': #ff8081,
  'A700': #ff6668,
  'contrastDefaultColor': $light-contrast-color,
  'contrastDarkColors': (
    '50',
    '100',
    '200',
    '300',
    'A100',
  ),
  'contrastStrongLightColors': (
    '400',
    '500',
    '600',
    '700',
    'A200',
    'A400',
    'A700',
  ),
);

// Pink
// ----------------------------

$color-pink: (
  '50': #fce4ec,
  '100': #f8bbd0,
  '200': #f48fb1,
  '300': #f06292,
  '400': #ec407a,
  '500': #e91e63,
  '600': #d81b60,
  '700': #c2185b,
  '800': #ad1457,
  '900': #880e4f,
  'A100': #ff80ab,
  'A200': #ff4081,
  'A400': #f50057,
  'A700': #c51162,
  'contrastDefaultColor': $light-contrast-color,
  'contrastDarkColors': (
    '50',
    '100',
    '200',
    'A100',
  ),
  'contrastStrongLightColors': (
    '500',
    '600',
    'A200',
    'A400',
    'A700',
  ),
) !default;

// Purple
// ----------------------------

$color-purple: (
  '50': #f3e5f5,
  '100': #e1bee7,
  '200': #ce93d8,
  '300': #ba68c8,
  '400': #ab47bc,
  '500': #9c27b0,
  '600': #8e24aa,
  '700': #7b1fa2,
  '800': #6a1b9a,
  '900': #4a148c,
  'A100': #ea80fc,
  'A200': #e040fb,
  'A400': #d500f9,
  'A700': #aa00ff,
  'contrastDefaultColor': $light-contrast-color,
  'contrastDarkColors': (
    '50',
    '100',
    '200',
    'A100',
  ),
  'contrastStrongLightColors': (
    '300',
    '400',
    'A200',
    'A400',
    'A700',
  ),
) !default;

// Deep Purple
// ----------------------------

$color-deep-purple: (
  '50': #ebebf1,
  '100': #cccddd,
  '200': #aaabc6,
  '300': #8889af,
  '400': #6f709e,
  '500': #55578d,
  '600': #4e4f85,
  '700': #44467a,
  '800': #3b3c70,
  '900': #2a2c5d,
  'A100': #a6a8ff,
  'A200': #7376ff,
  'A400': #4044ff,
  'A700': #262bff,
  'contrastDefaultColor': $light-contrast-color,
  'contrastDarkColors': (
    '50',
    '100',
    '200',
    'A100',
  ),
  'contrastStrongLightColors': (
    '300',
    '400',
    'A200',
  ),
) !default;

// Indigo
// ----------------------------

$color-indigo: (
  '50': #e8eaf6,
  '100': #c5cae9,
  '200': #9fa8da,
  '300': #7986cb,
  '400': #5c6bc0,
  '500': #3f51b5,
  '600': #3949ab,
  '700': #303f9f,
  '800': #283593,
  '900': #1a237e,
  'A100': #8c9eff,
  'A200': #536dfe,
  'A400': #3d5afe,
  'A700': #304ffe,
  'contrastDefaultColor': $light-contrast-color,
  'contrastDarkColors': (
    '50',
    '100',
    '200',
    'A100',
  ),
  'contrastStrongLightColors': (
    '300',
    '400',
    'A200',
    'A400',
  ),
) !default;

// Amber
// ----------------------------

$color-amber: (
  '50': #fff6e4,
  '100': #fee9bc,
  '200': #feda90,
  '300': #fecb64,
  '400': #fdc042,
  '500': #fdb521,
  '600': #fdae1d,
  '700': #fca518,
  '800': #fc9d14,
  '900': #fc8d0b,
  'A100': #ffffff,
  'A200': #fff9f2,
  'A400': #ffdfbf,
  'A700': #ffd3a6,
  'contrastDefaultColor': $light-contrast-color,
  'contrastDarkColors': (
    '50',
    '100',
    '200',
    'A100',
  ),
  'contrastStrongLightColors': (
    '300',
    '400',
    'A200',
    'A400',
  ),
) !default;

// Blue
// ----------------------------

$color-blue: (
  '50': #e3f2fd,
  '100': #bbdefb,
  '200': #90caf9,
  '300': #64b5f6,
  '400': #42a5f5,
  '500': #2196f3,
  '600': #1e88e5,
  '700': #1976d2,
  '800': #1565c0,
  '900': #0d47a1,
  'A100': #82b1ff,
  'A200': #448aff,
  'A400': #2979ff,
  'A700': #2962ff,
  'contrastDefaultColor': $light-contrast-color,
  'contrastDarkColors': (
    '50',
    '100',
    '200',
    '300',
    '400',
    'A100',
  ),
  'contrastStrongLightColors': (
    '500',
    '600',
    '700',
    'A200',
    'A400',
    'A700',
  ),
) !default;

// Light Blue
// ----------------------------

$color-light-blue: (
  '50': #e1f5fe,
  '100': #b3e5fc,
  '200': #81d4fa,
  '300': #4fc3f7,
  '400': #29b6f6,
  '500': #03a9f4,
  '600': #039be5,
  '700': #0288d1,
  '800': #0277bd,
  '900': #01579b,
  'A100': #80d8ff,
  'A200': #40c4ff,
  'A400': #00b0ff,
  'A700': #0091ea,
  'contrastDefaultColor': $dark-contrast-color,
  'contrastLightColors': (
    '600',
    '700',
    '800',
    '900',
    'A700',
  ),
  'contrastStrongLightColors': (
    '600',
    '700',
    '800',
    'A700',
  ),
) !default;

// Cyan
// ----------------------------

$color-cyan: (
  '50': #e0f7fa,
  '100': #b2ebf2,
  '200': #80deea,
  '300': #4dd0e1,
  '400': #26c6da,
  '500': #00bcd4,
  '600': #00acc1,
  '700': #0097a7,
  '800': #00838f,
  '900': #006064,
  'A100': #84ffff,
  'A200': #18ffff,
  'A400': #00e5ff,
  'A700': #00b8d4,
  'contrastDefaultColor': $dark-contrast-color,
  'contrastLightColors': (
    '700',
    '800',
    '900',
  ),
  'contrastStrongLightColors': (
    '700',
    '800',
    '900',
  ),
) !default;

// Teal
// ----------------------------

$color-teal: (
  '50': #e1f4f4,
  '100': #b3e4e5,
  '200': #81d3d3,
  '300': #4fc1c1,
  '400': #29b3b4,
  '500': #03a6a7,
  '600': #039e9f,
  '700': #029596,
  '800': #028b8c,
  '900': #017b7c,
  'A100': #a9feff,
  'A200': #76feff,
  'A400': #43fdff,
  'A700': #2afdff,
  'contrastDefaultColor': $dark-contrast-color,
  'contrastLightColors': (
    '500',
    '600',
    '700',
    '800',
    '900',
  ),
  'contrastStrongLightColors': (
    '500',
    '600',
    '700',
  ),
);

// Green
// ----------------------------

$color-green: (
  '50': #e8f5e9,
  '100': #c8e6c9,
  '200': #a5d6a7,
  '300': #81c784,
  '400': #66bb6a,
  '500': #4caf50,
  '600': #43a047,
  '700': #388e3c,
  '800': #2e7d32,
  '900': #1b5e20,
  'A100': #b9f6ca,
  'A200': #69f0ae,
  'A400': #00e676,
  'A700': #00c853,
  'contrastDefaultColor': $dark-contrast-color,
  'contrastLightColors': (
    '500',
    '600',
    '700',
    '800',
    '900',
  ),
  'contrastStrongLightColors': (
    '500',
    '600',
    '700',
  ),
);

// Light Green
// ----------------------------

$color-light-green: (
  '50': #f1f8e9,
  '100': #dcedc8,
  '200': #c5e1a5,
  '300': #aed581,
  '400': #9ccc65,
  '500': #8bc34a,
  '600': #7cb342,
  '700': #689f38,
  '800': #558b2f,
  '900': #33691e,
  'A100': #ccff90,
  'A200': #b2ff59,
  'A400': #76ff03,
  'A700': #64dd17,
  'contrastDefaultColor': $dark-contrast-color,
  'contrastLightColors': (
    '700',
    '800',
    '900',
  ),
  'contrastStrongLightColors': (
    '700',
    '800',
    '900',
  ),
) !default;

// Lime
// ----------------------------

$color-lime: (
  '50': #f9fbe7,
  '100': #f0f4c3,
  '200': #e6ee9c,
  '300': #dce775,
  '400': #d4e157,
  '500': #cddc39,
  '600': #c0ca33,
  '700': #afb42b,
  '800': #9e9d24,
  '900': #827717,
  'A100': #f4ff81,
  'A200': #eeff41,
  'A400': #c6ff00,
  'A700': #aeea00,
  'contrastDefaultColor': $dark-contrast-color,
  'contrastLightColors': (
    '900',
  ),
  'contrastStrongLightColors': (
    '900',
  ),
) !default;

// Yellow
// ----------------------------
$color-yellow: (
  '50': #fffde7,
  '100': #fff9c4,
  '200': #fff59d,
  '300': #fff176,
  '400': #ffee58,
  '500': #ffeb3b,
  '600': #fdd835,
  '700': #fbc02d,
  '800': #f9a825,
  '900': #f57f17,
  'A100': #ffff8d,
  'A200': #ffff00,
  'A400': #ffea00,
  'A700': #ffd600,
  'contrastDefaultColor': $dark-contrast-color,
) !default;

// Amber
// ----------------------------

$color-amber: (
  '50': #fff8e1,
  '100': #ffecb3,
  '200': #ffe082,
  '300': #ffd54f,
  '400': #ffca28,
  '500': #ffc107,
  '600': #ffb300,
  '700': #ffa000,
  '800': #ff8f00,
  '900': #ff6f00,
  'A100': #ffe57f,
  'A200': #ffd740,
  'A400': #ffc400,
  'A700': #ffab00,
  'contrastDefaultColor': $dark-contrast-color,
) !default;

// Orange
// ----------------------------

$color-orange: (
  '50': #fff3e0,
  '100': #ffe0b2,
  '200': #ffcc80,
  '300': #ffb74d,
  '400': #ffa726,
  '500': #ff9800,
  '600': #fb8c00,
  '700': #f57c00,
  '800': #ef6c00,
  '900': #e65100,
  'A100': #ffd180,
  'A200': #ffab40,
  'A400': #ff9100,
  'A700': #ff6d00,
  'contrastDefaultColor': $dark-contrast-color,
  'contrastLightColors': (
    '800',
    '900',
  ),
  'contrastStrongLightColors': (
    '800',
    '900',
  ),
) !default;

// Deep Orange
// ----------------------------

$color-deep-orange: (
  '50': #fbe9e7,
  '100': #ffccbc,
  '200': #ffab91,
  '300': #ff8a65,
  '400': #ff7043,
  '500': #ff5722,
  '600': #f4511e,
  '700': #e64a19,
  '800': #d84315,
  '900': #bf360c,
  'A100': #ff9e80,
  'A200': #ff6e40,
  'A400': #ff3d00,
  'A700': #dd2c00,
  'contrastDefaultColor': $light-contrast-color,
  'contrastDarkColors': (
    '50',
    '100',
    '200',
    '300',
    '400',
    'A100',
    'A200',
  ),
  'contrastStrongLightColors': (
    '500',
    '600',
    '700',
    '800',
    '900',
    'A400',
    'A700',
  ),
) !default;

// Brown
// ----------------------------

$color-brown: (
  '50': #efebe9,
  '100': #d7ccc8,
  '200': #bcaaa4,
  '300': #a1887f,
  '400': #8d6e63,
  '500': #795548,
  '600': #6d4c41,
  '700': #5d4037,
  '800': #4e342e,
  '900': #3e2723,
  'A100': #d7ccc8,
  'A200': #bcaaa4,
  'A400': #8d6e63,
  'A700': #5d4037,
  'contrastDefaultColor': $light-contrast-color,
  'contrastDarkColors': (
    '50',
    '100',
    '200',
    'A100',
    'A200',
  ),
  'contrastStrongLightColors': (
    '300',
    '400',
  ),
) !default;

// Grey
// ----------------------------
$color-grey: (
  '50': #fafafa,
  '100': #f5f5f5,
  '200': #eeeeee,
  '300': #e0e0e0,
  '400': #bdbdbd,
  '500': #9e9e9e,
  '600': #757575,
  '700': #616161,
  '800': #424242,
  '900': #212121,
  'A100': #ffffff,
  'A200': #000000,
  'A400': #303030,
  'A700': #616161,
  'contrastDefaultColor': $dark-contrast-color,
  'contrastLightColors': (
    '600',
    '700',
    '800',
    '900',
    'A200',
    'A400',
    'A700',
  ),
) !default;

// Blue Grey
// ----------------------------
$color-blue-grey: (
  '50': #eceff1,
  '100': #cfd8dc,
  '200': #b0bec5,
  '300': #90a4ae,
  '400': #78909c,
  '500': #607d8b,
  '600': #546e7a,
  '700': #455a64,
  '800': #37474f,
  '900': #263238,
  'A100': #cfd8dc,
  'A200': #b0bec5,
  'A400': #78909c,
  'A700': #455a64,
  'contrastDefaultColor': $light-contrast-color,
  'contrastDarkColors': (
    '50',
    '100',
    '200',
    '300',
    'A100',
    'A200',
  ),
  'contrastStrongLightColors': (
    '400',
    '500',
    '700',
  ),
) !default;

// Brand colors
$color-linced-blue: (
  '50': #e1edef,
  '100': #b3d1d8,
  '200': #80b3be,
  '300': #4d94a4,
  '400': #277d91,
  '500': #01667d,
  '600': #015e75,
  '700': #01536a,
  '800': #014960,
  '900': #00384d,
  'a100': #80d3ff,
  'a200': #4dc2ff,
  'a400': #1ab0ff,
  'a700': #01a7ff,
  'contrastDefaultColor': #ffffff,
  'contrastDarkColors': (
    '50',
    '100',
    '200',
    '300',
    'a100',
    'a200',
  ),
  'contrastStrongLightColors': (
    '400',
    '500',
    '700',
  ),
);

$color-linced-teal: (
  '50': #e9f6f6,
  '100': #d2eded,
  '200': #bce4e4,
  '300': #a5dbdb,
  '400': #8fd3d3,
  '500': #79caca,
  '600': #62c1c1,
  '700': #4cb8b8,
  '800': #35afaf,
  '900': #1fa6a6,
  'contrastDefaultColor': $dark-contrast-color,
);

$color-linced-purple: (
  '50': #eeeef4,
  '100': #dddde8,
  '200': #cccddd,
  '300': #bbbcd1,
  '400': #aaabc6,
  '500': #999abb,
  '600': #8889af,
  '700': #7779a4,
  '800': #666898,
  '900': #55578d,
  'contrastDefaultColor': $strong-light-contrast-color,
);

$color-linced-red: (
  '50': #fbe9e9,
  '100': #f7d3d4,
  '200': #f3bcbe,
  '300': #efa6a9,
  '400': #eb9093,
  '500': #e67a7d,
  '600': #e26468,
  '700': #de4d52,
  '800': #da373d,
  '900': #d62127,
  'contrastDefaultColor': $strong-light-contrast-color,
);

$color-linced-orange-yellow: (
  '50': #fff8e9,
  '100': #fff0d3,
  '200': #fee9bc,
  '300': #fee1a6,
  '400': #feda90,
  '500': #fed37a,
  '600': #fecb64,
  '700': #fdc44d,
  '800': #fdbc37,
  '900': #fdb521,
  'contrastDefaultColor': $dark-contrast-color,
);

$color-linced-yellow: (
  '50': #fffceb,
  '100': #fff8d6,
  '200': #fff5c2,
  '300': #fff2ad,
  '400': #ffef99,
  '500': #ffeb84,
  '600': #ffe870,
  '700': #ffe55b,
  '800': #ffe147,
  '900': #ffde32,
  'contrastDefaultColor': $dark-contrast-color,
);

$color-linced-orange: (
  '50': #fff3e7,
  '100': #ffe6cf,
  '200': #ffdab7,
  '300': #ffcd9f,
  '400': #ffc188,
  '500': #ffb470,
  '600': #ffa858,
  '700': #ff9b40,
  '800': #ff8f28,
  '900': #ff8210,
  'contrastDefaultColor': $dark-contrast-color,
);

$color-linced-pink: (
  '50': #fff3f7,
  '100': #fee7ee,
  '200': #fedbe6,
  '300': #fecfdd,
  '400': #fec3d5,
  '500': #fdb6cc,
  '600': #fdaac4,
  '700': #fd9ebb,
  '800': #fc92b3,
  '900': #fc86aa,
  'contrastDefaultColor': $dark-contrast-color,
);

$color-linced-light-green: (
  '50': #f5faee,
  '100': #ebf5dd,
  '200': #e0efcc,
  '300': #d6eabb,
  '400': #cce5aa,
  '500': #c2e098,
  '600': #b8db87,
  '700': #add576,
  '800': #a3d065,
  '900': #99cb54,
  'contrastDefaultColor': $dark-contrast-color,
);

$color-linced-dark-green: (
  '50': #eaf1e8,
  '100': #d4e3d2,
  '200': #bfd5bb,
  '300': #a9c7a5,
  '400': #94ba8e,
  '500': #7fac77,
  '600': #699e61,
  '700': #54904a,
  '800': #3e8234,
  '900': #29741d,
  'contrastDefaultColor': $dark-contrast-color,
);

$color-linced-light-blue: (
  '50': #f1f8fe,
  '100': #e4f1fc,
  '200': #d6eafb,
  '300': #c8e3f9,
  '400': #bbdcf8,
  '500': #add5f6,
  '600': #9fcef5,
  '700': #91c7f3,
  '800': #84c0f2,
  '900': #76b9f0,
  'contrastDefaultColor': $dark-contrast-color,
);
$color-linced-dark-blue: (
  '50': #e7eced,
  '100': #cfd8db,
  '200': #b7c5c9,
  '300': #9fb2b7,
  '400': #879fa6,
  '500': #6f8b94,
  '600': #577882,
  '700': #3f6570,
  '800': #27515e,
  '900': #0f3e4c,
  'contrastDefaultColor': $strong-light-contrast-color,
);

$colors: () !default;

$colors: map-merge(
  $colors,
  (
    'red': $color-red,
    'pink': $color-pink,
    'purple': $color-purple,
    'deep-purple': $color-deep-purple,
    'indigo': $color-indigo,
    'blue': $color-blue,
    'light-blue': $color-light-blue,
    'cyan': $color-cyan,
    'teal': $color-teal,
    'green': $color-green,
    'light-green': $color-light-green,
    'lime': $color-lime,
    'yellow': $color-yellow,
    'amber': $color-amber,
    'orange': $color-orange,
    'deep-orange': $color-deep-orange,
    'brown': $color-brown,
    'blue-grey': $color-blue-grey,
    'grey': $color-grey,
    'foreground-light': $foreground-light,
    'foreground-dark': $foreground-dark,
    // Brand colors
      'linced-blue': $color-linced-blue,
    'linced-teal': $color-linced-teal,
    'linced-purple': $color-linced-purple,
    'linced-red': $color-linced-red,
    'linced-orange-yellow': $color-linced-orange-yellow,
    'linced-yellow': $color-linced-yellow,
    'linced-orange': $color-linced-orange,
    'linced-pink': $color-linced-pink,
    'linced-light-green': $color-linced-light-green,
    'linced-dark-green': $color-linced-dark-green,
    'linced-light-blue': $color-linced-light-blue,
    'linced-dark-blue': $color-linced-dark-blue,
  )
);
