/**
 * linced-common-components provides styles for our components in BEM format.
 *
 * Components and styles are listed in alphabetic order.
 *
 * Block = Component.
 * Element = Component Element.
 * Modifier = State that modifies the element's style.
 *
 * Refer:
 * - http://getbem.com/naming/
 */

// Imports
@import 'hero-palette';
@import 'linced-goals';
@import 'linced-mfa';
@import 'linced-parent-homepage';

/**
 * dialog-escaper component.
 */
.dialog-escaper {
  position: absolute;
  right: 0;
  top: 0;

  // Overrides
  .md-button.md-icon-button {
    margin: 6px;
  }
}

/**
 * environment-warning component styles.
 *
 * Note:
 * - See CSS .environment-warning override for pushing the element left when
 *   the sidenav bar is locked open in `linced-common-overrides.scss`.
 */
.environment-warning {
  background-color: paper-color($warn, '400');
  color: #fff;
  font-size: smaller;
  padding: 1px;
  position: fixed;
  top: 56px;
  left: 0;
  right: 0;
  z-index: 20;

  // __hide-btn provides styles for the environment-warning close/dismiss
  // button.
  &__hide-btn {
    position: fixed;
    left: unset;
    right: 0;
    top: 56px;
  }

  // Overrides
  md-icon {
    color: #fff;
  }
}

/**
 * image-uploader component.
 */
.image-uploader {
  // Overrides
  .uppy-DragDrop-container {
    opacity: 0;
  }

  // If there is no current content, or the user is dragging an image,
  // make the drop target visible.
  .uppy-DragDrop-container.uppy-DragDrop--is-dragdrop-supported.drag {
    opacity: 1;
    z-index: 6;
  }

  // Modifiers
  &--constrain-size {
    width: 120px;
    height: 120px;
    display: block;
    position: relative;

    // Overrides
    md-progress-circular {
      margin: 35px auto;
    }

    img {
      width: 120px;
      height: 120px;
      object-fit: cover;
    }

    .uppy-DragDrop-container {
      opacity: 0;
      //display: block;
      position: absolute;
      z-index: 4;
      top: 0;
      left: 0;

      .uppy-DragDrop-inner {
        padding: 12px;
        width: 100%;
        height: 100%;
      }

      .uppy-DragDrop-arrow {
        width: 28px !important;
        height: 28px !important;
        margin-bottom: 8px !important;
      }

      .uppy-DragDrop-label {
        font-size: 1em !important;
        line-height: 1.2 !important;
        width: 100%;
        height: auto;
      }
    }
  }

  &--is-empty {
    z-index: 6;
    display: block;

    // Overrides
    .uppy-DragDrop-container {
      opacity: 1;
    }
  }

  &--uploading {
  }
}

// Size/align image-uploader's Uppy dragdrop target within post-attachments
.newpost-attachments .post-attachment .image-uploader--constrain-size {
  width: 118px;
  height: 90px;

  .uppy-DragDrop-container {
    opacity: 1;

    .uppy-DragDrop-inner {
      padding: 4px 12px !important;
    }

    .uppy-DragDrop-arrow {
      width: 16px !important;
      height: 16px !important;
      margin-bottom: 4px !important;
    }

    .uppy-DragDrop-label {
      font-size: 0.8em !important;
    }
  }
}

/**
 * goals-progress-details component.
 */
.goals-progress-details {
  &__chip {
    md-chip {
      cursor: pointer;
      width: 120px;
      text-align: center;

      .md-chip-content {
        float: none;
      }
    }

    md-chips span.goal-details-label,
    .md-contact-chips .md-chips md-chip .md-contact-name {
      display: inline;
    }
  }

  // __dialog provides styles for the the pop-up dialog.
  &__dialog {
    max-height: 80vh;
    overflow: visible;
    top: 5vh;

    // -content provides styles for the dialog content.
    &-content {
      padding: 24px;

      // --with-showcase-image pushes the content down to allow spacing for
      // the showcased badge image.
      &--with-showcase-image {
        padding-top: 0;
        margin-top: 95px;
      }
    }

    // -goal-label configures the styles for the goal labels.
    &-goal-label {
      &--not-set {
        color: $color-state-not-set;
      }

      &--developing {
        color: $color-state-developing-text;
      }

      &--secure {
        color: $color-state-secure;
      }
    }

    // -showcase-badge provides a wrapper container to center the badge.
    &-showcase-badge {
      display: block;
      margin: auto;
      position: relative;
      right: 10px;
      top: -70px;
    }

    // -showcase-image styles the badge image to be centered and be aspect
    // constrained.
    &-showcase-image {
      left: 0;
      margin: auto;
      object-fit: cover;
      position: fixed;
      right: 0;
    }

    // Overrides
    .md-dialog-content {
      padding-top: 0;

      &.goals-progress-details__dialog-content {
        // When there is no image, push con
        padding-top: 24px;
      }
    }
  }
}

/**
 * log-card component styles.
 */
.log-card {
  // __card provides styles for the card.
  &__card {
    // Modifiers
    &--error {
      background-color: paper-color($color-deep-orange, '200');
    }

    &--info {
      background-color: paper-color($color-light-blue, '100');
    }

    &--warn {
      background-color: paper-color($color-amber, '200');
    }
  }
}

/**
 * post-comment component styles
 */
.post-comment {
  // __action provides a style for comment actions, that is reply, edit delete.
  &__action {
    padding-right: 8px;
    display: flex;
    flex-direction: row;
  }
}

/**
 * profile-banner component styles
 */
.profile-banner {
  // __heroid provides a thin-font to separate the Hero ID from the surrounding
  // display text in the headline.
  &__heroid {
    font-size: 18px;
    font-weight: 200;
  }
}

// report-printer component styles
.report-printer-frame {
  height: 1px;
  width: 1px;
  position: absolute;
  top: -100px;
  left: -100px;
}

/**
 * video-card component styles.
 */
.log-card {
  &__content {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }

  &__video-player {
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    top: 16px;
    left: 16px;
    position: absolute;
    border-radius: 10px;
    border: none;
  }
}

.wonde-sync-overview {
  &--dialog-content {
    &--content-wrapper {
      width: 100%;
      display: flex;
    }

    &--list-item {
      width: 100%;

      &--text {
        float: left;
      }
    }

    &--card {
      &--scroll-view {
        overflow-y: auto;
      }
    }

    &--container {
      &--refresh.md-button.md-icon-button {
        height: 36px;
        width: 36px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);

        .paper-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, calc(-50% + 1px));
        }
      }

      .md-list-item-inner.md-no-style {
        padding-top: 0;
      }

      .md-subheader-inner {
        padding: 0;

        .md-subheader-content {
          align-items: center;

          h3 {
            padding: 16px;
            margin: 0;
          }
        }
      }
    }

    .selected {
      background-color: paper-color($color-grey, '200');
    }
  }

  &--error-label {
    display: flex;
    flex-wrap: wrap;

    small {
      width: 50%;
    }
  }
}

.progress-bar {
  width: 100%;
  display: flex;
  align-items: center;

  &--container {
    width: 100%;
    background-color: #ccc;
    border-radius: 999999px;
    height: 20px;
    margin: 0 8px;
    overflow: hidden;
  }

  &--fill {
    height: 100%;
    transition: all 0.5s;
    background-color: paper-color($color-green, '300');
  }
}

/**
 * Call to action component styles.
 */

.cta {
  &__title {
    &__container {
      padding-bottom: 10px;
    }

    &__text {
      font-weight: 700;
      font-size: 1.1rem;
      line-height: normal;
    }
  }

  &__icon {
    color: paper-color($color-linced-blue);
    margin-top: 0;
  }

  &__link {
    font-size: 1rem;
    font-weight: 700;
    color: paper-color($color-linced-blue);
    margin-top: 10px;
  }

  &__link:hover {
    text-decoration: underline;
  }
}

/**
 * School details component styles.
 */
.school-details {
  &__field {
    color: paper-color($color-linced-blue);
  }

  &__icon {
    margin-right: 20px;
    color: paper-color($color-linced-blue);
  }

  &__address {
    &--secondary {
      font-size: smaller;
      color: paper-color($color-grey, '600');
    }
  }
}

/**
 * Fullscreen attachment styles.
 */

.fullscreen-attachment {
  &__thumbnail {
    cursor: zoom-in;
  }

  &__container {
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  &__button {
    // The open fullscreen button mode
    background-color: paper-color($foreground-light, '1');
    border-radius: 2px !important;
    margin: 0;
    position: absolute;
    right: 10px;
    top: 10px;

    i.ph-bold.ph-arrows-out-simple {
      display: block;
      margin-left: 1px;
    }

    &--close {
      // Bring it before the backdrop to be visible
      background-color: var(--zoomist-zoomer-button-color);
      color: var(--zoomist-zoomer-icon-color);
      margin: 0;
      right: calc(var(--safe-area-inset-right) + 10px);
      top: calc(var(--safe-area-inset-top) + 10px);
      z-index: 100;

      opacity: 0.7;
      transition: all 0.2s;

      &:hover {
        opacity: 1;
      }
    }

    &--download {
      // Bring it before the backdrop to be visible
      background-color: var(--zoomist-zoomer-button-color);
      color: var(--zoomist-zoomer-icon-color);
      margin: 0;
      right: calc(var(--safe-area-inset-right) + 10px);
      top: calc(var(--safe-area-inset-top) + 64px);
      z-index: 100;

      opacity: 0.7;
      transition: all 0.2s;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__button:hover button {
    background-color: paper-color($foreground-light, '1');
  }

  &__item {
    // Bring it before the backdrop to not be clickable
    z-index: 100;

    img {
      width: 100%;
      object-fit: contain;
      object-position: center;
    }

    width: 100%;
    aspect-ratio: 1;
  }

  &__item:has(> iframe) {
    background-color: paper-color($foreground-light, '1');
  }

  &__backdrop {
    // Indicate to users that by clicking on the backdrop,
    // they can close the fullscreen mode
    cursor: zoom-out;
    z-index: -1 !important;
  }

  @media (min-width: $layout-breakpoint-md) {
    &__container {
      max-width: 50vw;
      overflow: visible;
    }
    &__item {
      img {
        height: 100%;
      }
    }
  }
}

.zoomist-container {
  .fullscreen-button-prev,
  .fullscreen-button-next {
    position: absolute;
    top: 50%;
    margin-top: -16px;
    opacity: 0.7;
    transition: all 0.2s;

    &:hover {
      opacity: 1;
    }
  }

  .fullscreen-button-prev {
    left: 8px;
  }

  .fullscreen-button-next {
    right: 8px;
  }

  md-backdrop.md-opaque {
    opacity: 0.95;
  }

  .zoomist-wrapper {
    width: 100%;
    height: 100vh;
    background-color: transparent !important;
  }

  .zoomist-image {
    // Has to be set full height of the container before the bug with jumping y-axis location
    // is fixed in the next version
    height: 100%;
    align-content: center;
    display: flex;

    &.interactable {
      img {
        pointer-events: all;
      }
    }
  }

  .zoomist-zoomer {
    bottom: calc(var(--safe-area-inset-bottom) + 12px);
    right: auto;
    top: auto;
    display: flex;
    border-radius: 4px;
    box-shadow: 0 1px 4px 1px #0000003d;

    .zoomist-zoomer-button {
      width: 42px;
      height: 42px;
    }

    .zoomist-zoomer-in {
      order: 1;
    }
  }
}


// Reactions widget
.reactions {
  font-size: 0.875rem; //14px with base of 16px
  // Reactions icons
  &__container {
    &.ember-popover {
      padding: 0;
    }
  }

  img {
    margin-right: 5px;
    vertical-align: text-bottom;
  }

  &__react {
    &__inner {
      font-weight: 400;
    }

    &__emoji {
      font-size: 16px;

      &--bigger {
        font-size: 20px;
        // Center the emoji in the middle of the button
        display: flex;
        justify-content: center;

      }
    }
  }

  &__count {
    border-radius: 4px;
    border: 1px solid paper-color($color-grey, '100');
    background-color: paper-color($color-grey, '50');
    height: 100%;
    padding: 3px;
    margin-right: 3px;
  }
}

.field-filters {
  background-color: white !important;

  td.md-cell {
    // Uniform right-padding on cells containing filter boxes
    padding-right: 12px !important;

    > md-input-container {
      // Filter boxes should fill the cell
      width: 100%;
      margin: 0;
      padding-left: 28px;

      // Icons should be small (to match smaller input-within-table-cell) and light grey when unfocused
      > md-icon {
        font-size: 18px;
        max-height: 18px;
        max-width: 18px;
        height: 18px;
      }
      &:not(.md-focused) > md-icon {
        color: paper-color($color-grey, '400');
      }

      // Has a value (and isn't invalid)
      &.md-input-has-value:not(.md-input-invalid) {
        md-icon {
          color: paper-color($color-green, '500');
        }
        .md-input {
          border-color: paper-color($color-green, '500');
          border-width: 0 0 2px 0;
          padding-bottom: 0;
        }
      }

      // No gap to fit error messages into, unless we have errors to display
      .md-errors-spacer {
        display: none;
      }
      &.md-input-invalid .md-errors-spacer {
        display: block;
      }
    }
  }
}
