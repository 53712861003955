/**
 * Imports
 */
@import 'hero-palette';
@import 'ember-paper-stepper';

/**
 * Styles for MFA-related components
 */

.mfa {
  /**
   * Enrollment dialog under profile settings
   */
  &__enrolment-dialog {
    max-width: 600px;

    md-dialog .md-dialog-content {
      padding-top: 0;
    }

    .md-steppers-alternative .md-steppers-horizontal .md-stepper-indicator {
      // Make the container with steps' names smaller
      padding: 16px;
      min-height: fit-content;
      &:after {
        top: 30px;
      }
    }

    &__steps {
      padding: 0;

      // Provide with more spacing between enrolment steps
      > li,
      span {
        margin-bottom: 8px;
      }
      > span:last-child {
        margin-bottom: 16px;
      }
    }

    &__qr-code {
      // Make the QR code smaller
      align-self: center;
      height: 200px;
      width: 200px;
    }

    &__icon {
      &--green {
        color: paper-color($color-green, '400');
      }
    }
    /**
     * Note-card component for recovery codes
     */
    &__recovery-codes {
      margin: 36px 0;

      md-card-content {
        display: flex;
        flex-wrap: wrap;
        column-gap: 16px;
        > span {
          padding-bottom: 8px;
          font-weight: 700;
        }
      }
      button:hover {
        md-icon {
          color: paper-color($color-linced-blue, '500');
        }
      }
    }
  }

  /**
   * TOTP code component that can be used in multiple scenarios for MFA
   */
  &__totp-code {
    &__container {
      margin: 12px 0;

      md-input-container .md-input {
        border-width: 1px;
        width: 40px;
        height: 60px;
        text-align: center;
        font-size: 130%;
      }
      input:focus {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
      }
    }

    &__message {
      font-weight: 500;
      margin-top: 12px;

      &--failure {
        color: paper-color($color-red, '500');
      }
    }

    &__progress-overlay {
      md-progress-circular {
        align-self: center;
        margin-bottom: 10px;
      }
    }
  }
  /**
   * MFA Management dialog under profile settings
   */
  &__user-management {
    &__dialog {
      min-width: 400px;

      .md-subheader {
        background-color: transparent;
      }
      .md-subheader .md-subheader-inner {
        padding: 16px 0 0;
      }
      md-divider:last-child {
        display: none;
      }
    }
  }
  /**
   * MFA Un-enrolment dialog
   */
  &__unenrolment {
    &__dialog {
      width: 500px;
    }
    &__button {
      &--mode-switch {
        margin-left: 0 !important;
      }
    }
    &__container {
      &--center {
        align-self: center;
      }
    }
  }

  &__message {
    &--warning {
      font-weight: 500;
      color: paper-color($color-red, '500');
    }
  }
}
