#main .assessments {
  &__container {
    display: block;
    padding: 16px 0;
  }
  .assessments__container > .dragSortList > .dragSortItem > .tree-branch,
  .assessments__container > .dragSortList > .dragSortItem > .tree-node {
    min-height: 64px;
    padding: 8px 0 0 8px;
  }
  .assessments__container > .dragSortList > .dragSortItem:nth-of-type(odd) {
    background-color: #e1edef;
  }

  .assessments__container > .dragSortList:nth-of-type(odd) {
    padding-bottom: 32px;
    border-bottom: 1px solid var(--foreground-4);
  }

  .assessments__container > .dragSortList:nth-of-type(even) {
    padding-top: 32px;
  }

}
